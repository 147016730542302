import React from 'react';
import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ItemForm from './ItemForm';
import { addItemsToList, selectContext, updateItemBatch } from '../redux/features/curatedSlice';

export default function ItemEditDialog(props) {
  const { open, onClose } = props;

  const context = useSelector(selectContext);
  const ids = (context && context.items && context.items.map((item) => item.id))
    || [];

  const dispatch = useDispatch();

  const onSaveItem = (item) => {
    dispatch(updateItemBatch({ ids, patch: item }));
    onClose();
  };

  const onItemAddToList = (item, listInfo) => {
    const items = ids.map((id) => ({ id, ...item }));
    dispatch(addItemsToList({ items, listId: listInfo.id }));
    onClose();
  };

  return (
    <Dialog maxWidth="lg" open={open} fullWidth onClose={onClose}>
      <ItemForm
        item={{}}
        disableValidation
        onCancel={onClose}
        onItemAddToList={onItemAddToList}
        onSaveItem={onSaveItem}
      />
    </Dialog>
  );
}
