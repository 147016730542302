import moment from 'moment';

export function convertUnixTimeToDate(timestamp) {
  return new Date(timestamp * 1000);
}

export function convertUnixTimeToMoment(timestamp) {
  const date = new Date(timestamp * 1000);
  return moment(date).fromNow();
}

export function convertUnixTimeToReadable(timestamp) {
  const date = new Date(timestamp * 1000);
  return moment(date).format('MMMM Do YYYY, h:mm a');
}

export function splitQuotedString(query) {
  const myRegexp = /[^\s"]+|"([^"]*)"/gi;
  const result = [];
  let match;
  do {
    match = myRegexp.exec(query);
    if (match) {
      result.push(match[1] ? match[1] : match[0]);
    }
  } while (match);

  return result;
}

export function getImageUrl(item) {
  if ('coverImageUrl' in item) {
    return item.coverImageUrl;
  }
  return null;
}

export function getAuthorImageUrl(author) {
  if (!author || !author.profiles || !author.profiles.length) {
    return null;
  }

  for (let i = 0; i < author.profiles.length; i += 1) {
    const profile = author.profiles[i];
    if (profile.imageUrl) return profile.imageUrl;
  }

  return null;
}

export function getAuthorUrl(author) {
  if (!author || !author.profiles || !author.profiles.length) {
    return null;
  }

  for (let i = 0; i < author.profiles.length; i += 1) {
    const profile = author.profiles[i];
    if (profile.url) return profile.url;
  }

  return null;
}

export function isNotPublic(item) {
  return item.visibility !== 'public';
}

export function compareLowerCase(value1, value2) {
  return value1.toLowerCase().includes(value2.toLowerCase());
}

export function applyItemFilter(items, filter, extraFilter = null) {
  return items.filter((item) => typeof (filter) !== 'string'
      || (
        ((extraFilter && extraFilter(item)) || !extraFilter)
        && (
          (item.id && compareLowerCase(item.id, filter))
          || (item.title && compareLowerCase(item.title, filter))
          || (item.excerpt && compareLowerCase(item.excerpt, filter))
          || (item.sourceUrl && compareLowerCase(item.sourceUrl, filter))
          || (item.authors && item.authors.find(
            (author) => author.name && compareLowerCase(author.name, filter),
          ))
        )
      ));
}

export function getListTitle(listInfo) {
  if (!listInfo) return null;

  if (listInfo.privateData && listInfo.publicData) {
    return { ...listInfo.publicData, ...listInfo.privateData };
  }

  if (listInfo.privateData) return listInfo.privateData;

  return listInfo.publicData;
}

export function calculateCompletenessScore(item) {
  let points = 0;
  points += item.title ? 10 : 0;
  points += item.sourceUrl ? 10 : 0;
  points += item.authors ? 10 : 0;
  points += item.coverImageUrl ? 10 : 0;
  points += item.excerpt ? 10 : 0;
  points += item.type ? 10 : 0;
  points += item.lang ? 10 : 0;
  points += item.timeNeeded ? 10 : 0;
  points += item.timePublished ? 10 : 0;
  points += item.tags ? 10 : 0;

  return Math.round(points);
}

export function calculateAuthorInfoScore(author) {
  const { privateData: authorInfo } = author;
  if (!authorInfo || !authorInfo.profiles || !authorInfo.profiles.length) return 0;
  let points = 0;
  points += authorInfo.profiles[0].name ? 10 : 0;
  points += authorInfo.profiles[0].url ? 10 : 0;
  points += authorInfo.profiles[0].imageUrl ? 10 : 0;

  return Math.round((points / 30) * 100);
}

export function humanizeIntervalInMinutes(minutes) {
  return moment.duration(minutes, 'minutes').humanize();
}

export function getLogoUrl(item) {
  if (Object.prototype.hasOwnProperty.call(item, 'domainMetadata')) {
    return item.domainMetadata.logo;
  }

  return null;
}

export function getDomainFromUrl(url) {
  const parser = document.createElement('a');
  parser.href = url;
  return parser.hostname;
}

export function getDomain(item) {
  return item.sourceUrl ? getDomainFromUrl(item.sourceUrl) : '';
}

export function getSourceLetter(item) {
  const domain = getDomain(item);
  if (domain) {
    const arr = domain.split('.');
    if (arr[0] === 'www' || arr[0] === 'm') {
      return arr[1][0].toUpperCase();
    }
    return arr[0][0].toUpperCase();
  } if (item.title) {
    return item.title[0].toUpperCase();
  }
  return '-';
}

export function mergeAuthorIds(item) {
  let set1 = new Set();
  if (item.privateData && item.privateData.authorIds) {
    set1 = new Set(item.privateData.authorIds);
  }

  let set2 = new Set();
  if (item.publicData && item.publicData.authorIds) {
    set2 = new Set(item.privateData.authorIds);
  }

  return Array.from(new Set(set1, set2));
}

export function getProfileName(profile) {
  return profile && (profile.name || profile.username);
}

export function getAuthorInfoName(authorInfo) {
  if (!authorInfo || !authorInfo.profiles || !authorInfo.profiles.length) {
    return null;
  }

  let username = null;
  for (let i = 0; i < authorInfo.profiles.length; i += 1) {
    const profile = authorInfo.profiles[i];
    if (profile.name) {
      return profile.name;
    }

    if (!username && profile.username) {
      username = profile.username;
    }
  }

  return username ? `@${username}` : null;
}

export function getAuthorName(author) {
  if (!author) return null;

  let name;
  if (author.privateData) {
    name = getAuthorInfoName(author.privateData);
    if (name) return name;
  }

  if (author.publicData) {
    return getAuthorInfoName(author.publicData);
  }

  return null;
}

export function getAuthor(item) {
  if ('authors' in item && item.authors.length > 0) {
    const a0 = item.authors[0];
    return { id: a0.id, name: getAuthorName(a0) };
  }
  return { name: getDomain(item) };
}

export function round(value, decimals) {
  return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

export function estimateReadTime(wordCount) {
  if (wordCount !== '0') {
    return `${round(wordCount / 250, 0)} minutes read`;
  }
  return '';
}

export function createTagPages(tags, pageSize) {
  const pages = [];
  let currentPage = [];
  let currentPageSize = 0;

  for (let i = 0; i < tags.length; i += 1) {
    if (currentPageSize === pageSize) {
      pages.push(currentPage);
      currentPage = [];
      currentPageSize = 0;
    }
    currentPage.push(tags[i]);
    currentPageSize += 1;
  }

  if (currentPage.length) {
    pages.push(currentPage);
  }

  return pages;
}

export function mergePublicAndPrivateItems(item) {
  if (!item || !(item.privateData || item.publicData)) {
    return null;
  }
  if (!item.publicData) {
    return { ...item.privateData, hasPrivateData: true };
  }

  if (!item.privateData) {
    return item.publicData;
  }

  return { ...item.publicData, ...item.privateData, hasPrivateData: true };
}

export function mergePublicAndPrivateAuthors(author) {
  if (!author || !(author.privateData || author.publicData)) {
    return null;
  }
  if (!author.publicData) {
    return { ...author.privateData, hasPrivateData: true };
  }

  if (!author.privateData) {
    return author.publicData;
  }

  return { ...author.publicData, ...author.privateData, hasPrivateData: true };
}

export function isPublicNamespace(namespace) {
  return namespace === 'public';
}

export function mustSignIn(namespace, page, userProfile) {
  const isPublic = isPublicNamespace(namespace);
  if (isPublic && !userProfile && page > 1) {
    return true;
  }

  if (!isPublic && !userProfile) {
    return true;
  }

  return false;
}
