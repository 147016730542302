import React from 'react';
import PropTypes from 'prop-types';
import { Button, MobileStepper } from '@mui/material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { styled } from '@mui/system';

const StyledStepper = styled(MobileStepper)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
}));

export default function PageStepper(props) {
  const {
    pagesCount, pageNumber, handleNextPage, handlePreviousPage,
  } = props;

  return (
    <StyledStepper
      activeStep={pageNumber - 1}
      backButton={(
        <Button disabled={pageNumber === 1} onClick={handlePreviousPage}>
          <KeyboardArrowLeft />
          Back
        </Button>
      )}
      nextButton={(
        <Button disabled={pageNumber === pagesCount} onClick={handleNextPage}>
          Next
          <KeyboardArrowRight />
        </Button>
      )}
      position="static"
      steps={pagesCount}
      variant="text"
    />
  );
}

PageStepper.propTypes = {
  pagesCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  handlePreviousPage: PropTypes.func.isRequired,
};
