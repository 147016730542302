import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Grid, Chip, useMediaQuery, IconButton,
} from '@mui/material';
import { compareLowerCase, createTagPages } from '../common/ItemTools';

export default function TagCarousel(props) {
  const { tags, tagActions, filter } = props;

  const onTagOpen = tagActions ? tagActions.onTagOpen : undefined;

  const filteredTags = tags
    && filter
    ? tags.filter((tag) => compareLowerCase(tag, filter))
    : tags;
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const tagPages = tags && createTagPages(filteredTags, matches ? 15 : 1);
  const [page, setPage] = useState(0);

  return (
    <Grid key="tags" xs={12} item>
      { tagPages
        && tagPages[page]
        && (
          <div style={{
            margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            <IconButton
              disabled={page === 0}
              sx={{ margin: '5px', marginRight: 'auto', color: 'secondary.main' }}
              onClick={() => setPage(page - 1)}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <div>
              {
                tagPages[page].map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    sx={{ margin: '5px' }}
                    onClick={() => onTagOpen(tag)}
                  />
                ))
              }
            </div>
            <IconButton
              disabled={page === tagPages.length - 1}
              sx={{ margin: '5px', marginLeft: 'auto', color: 'secondary.main' }}
              onClick={() => setPage(page + 1)}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        )}
    </Grid>
  );
}

TagCarousel.propTypes = {
  tags: PropTypes.array,
  tagActions: PropTypes.object,
};

TagCarousel.defaultProps = {
  tags: null,
  tagActions: null,
};
