import React from 'react';
import {
  Checkbox, FormControlLabel, FormGroup, Switch, Toolbar,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo } from '../redux/features/usersSlice';

function SettingsView() {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.users.darkMode)
    || false;
  const anyLanguage = useSelector((state) => state.users.anyLanguage)
    || false;
  const pocketConnected = useSelector((state) => state.users.pocketConnected)
    || false;
  const token = useSelector((state) => state.users.authToken);

  const handleDarkMode = (newDarkMode) => {
    dispatch(updateUserInfo({ darkMode: newDarkMode }));
  };

  const handleAnyLanguage = (newAnyLanguage) => {
    dispatch(updateUserInfo({ anyLanguage: newAnyLanguage }));
  };

  const handlePocketConnect = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/auth/pocket`
      + `?redirect_uri=${window.location}`,
      {
        method: 'GET',
        headers: {
          Authorization: `${token.token_type} ${token.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    )
      .then((res) => res.json())
      .then((json) => { window.location = json.url; });
  };

  return (
    <>
      <Toolbar />
      <FormGroup style={{ alignItems: 'center' }}>
        <FormControlLabel
          control={
            (
              <Switch
                checked={darkMode}
                size="medium"
                onChange={() => handleDarkMode(!darkMode)}
              />
            )
          }
          label="Dark Mode"
          labelPlacement="start"
        />
        <FormControlLabel
          control={
            (
              <Switch
                checked={anyLanguage}
                size="medium"
                onChange={() => handleAnyLanguage(!anyLanguage)}
              />
            )
          }
          label="Any Language"
          labelPlacement="start"
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={pocketConnected}
              onChange={() => handlePocketConnect(pocketConnected)}
            />
          )}
          label="Connect with Pocket"
          labelPlacement="start"
        />
      </FormGroup>
    </>
  );
}

export default SettingsView;
