/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import * as React from 'react';
import { DateTimePicker as MuiPicker } from '@mui/x-date-pickers/DateTimePicker';
import { getIn } from 'formik';

export function fieldToDatePicker({
  field: { onChange: _onChange, ...field },
  form: {
    isSubmitting, touched, errors, setFieldValue, setFieldError, setFieldTouched,
  },
  textField: { helperText, onBlur, ...textField } = {},
  disabled,
  label,
  onChange,
  onError,
  ...props
}) {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    slotProps: {
      textField: {
        error: showError,
        helperText: showError ? fieldError : helperText,
        label,
        onBlur:
          onBlur
          || (() => {
            setFieldTouched(field.name, true, true);
          }),
        ...textField,
      },
    },
    disabled: disabled || isSubmitting,
    onChange:
      onChange
      || ((date) => {
        // Do not switch this order, otherwise you might cause a race condition
        // See https://github.com/formium/formik/issues/2083#issuecomment-884831583
        setFieldTouched(field.name, true, false);
        setFieldValue(field.name, date, true);
      }),
    onError: onError
    || (() => { setFieldError(field.name, fieldError); }),
    ...field,
    ...props,
  };
}

export function FormikDateTimePicker({ ...props }) {
  // custom props
  props.textField = {
    ...props.textField,
    size: 'small',
  };

  return (
    <MuiPicker
      {...fieldToDatePicker(props)}
    />
  );
}
