import { CircularProgress, Grid, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ItemCardGrid from '../components/ItemCardGrid';
import TagCarousel from '../components/TagCarousel';
import {
  addItemsToList, approveItem, cloneItem, createItem, createListInfo, deleteItem,
  getNamespacePath, mergeItems, selectAllTagsPrivate, selectAllTagsPublic,
  selectContext, setContext, shareItem, updateItem,
} from '../redux/features/curatedSlice';
import { selectUserProfile } from '../redux/features/usersSlice';
import StyledBackdrop from '../components/StyledBackdrop';
import Login from '../components/Login';
import { isPublicNamespace, mustSignIn } from '../common/ItemTools';

function contextUpdate(context, searchParams, listId, authorId, view) {
  const params = new URLSearchParams(searchParams);
  const page = +params.get('page');
  const filter = params.get('filter');

  const contextParams = context
    ? {
      pageNumber: context.pageNumber,
      ...(context.filter && { filter: context.filter }),
      ...(context.listId && { listId: context.listId }),
      ...(context.authorId && { authorId: context.authorId }),
      ...(context.view && { view: context.view }),
    } : {};

  const update = {
    pageNumber: page || 1,
    ...(filter && { filter }),
    ...(listId && { listId }),
    ...(authorId && { authorId }),
    ...(view && { view }),
  };

  if (JSON.stringify(contextParams) !== JSON.stringify(update)) {
    return update;
  }
}

function searchParamsUpdate(context, searchParams) {
  const update = context
    ? {
      ...(context.pageNumber && context.pageNumber !== 1 && { page: context.pageNumber }),
      ...(context.filter && { filter: context.filter }),
    } : {};

  const params = searchParams.toString();
  const result = new URLSearchParams(update).toString();
  if (params !== result) {
    return result;
  }

  return null;
}

function addPages(searchParams, increment) {
  const page = searchParams.get('page');
  const filter = searchParams.get('filter');

  const newPage = (page ? +page : 1) + increment;
  const params = {
    ...(newPage > 1 && { page: newPage }),
    ...(filter && { filter }),
  };

  return new URLSearchParams(params).toString();
}

function ItemsView(props) {
  const { namespace, type: propType, view } = props;
  const type = propType || 'items';
  const { listId, authorId } = useParams();
  const isPublic = isPublicNamespace(namespace);

  const [searchParams] = useSearchParams();
  const page = +searchParams.get('page');
  const filter = searchParams.get('filter');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userProfile = useSelector(selectUserProfile);
  const status = useSelector((state) => state.curated.contextStatus);
  const currContext = useSelector(selectContext);
  const userInfo = useSelector((state) => state.users);
  const tags = useSelector(isPublic ? selectAllTagsPublic : selectAllTagsPrivate);

  const forceSignIn = mustSignIn(namespace, page, userProfile);
  const namespacePath = getNamespacePath(namespace);
  const showDelete = (isPublic && userInfo.isCurator) || !isPublic;

  let context = null;
  if (!forceSignIn && currContext && currContext.type === type
    && currContext.namespace === namespace) {
    context = currContext;
  }

  useEffect(() => {
    if (forceSignIn || status === 'loading') {
      return;
    }
    const update = contextUpdate(context, searchParams, listId, authorId, view);
    if (update) {
      const newContext = {
        ...(namespace && { namespace }),
        ...(view && { view }),
        type,
        ...(listId && { listId }),
        ...(authorId && { authorId }),
        ...update,
      };
      dispatch(setContext(newContext));
    }
  }, [authorId, filter, listId, namespace, page, view, userProfile]);

  useEffect(() => {
    if (forceSignIn || status === 'loading') return;
    const update = searchParamsUpdate(context, searchParams);
    if (update !== null) { navigate({ search: update }); }
  }, [status]);

  const handleNextPage = () => navigate({ search: addPages(searchParams, 1) });
  const handlePreviousPage = () => navigate({ search: addPages(searchParams, -1) });

  const onItemOpen = (item) => window.open(item.sourceUrl, '_blank');
  const onItemRead = (item) => navigate(`/items/${item.id}`);
  const onItemDelete = (item) => dispatch(deleteItem({ item, namespace, refresh: true }));
  const onItemBookmark = (item) => dispatch(createItem({ item }));
  const onItemShare = (item) => dispatch(shareItem({ item }));
  const onItemApprove = (item) => dispatch(approveItem(item));
  const onItemClone = (item) => dispatch(cloneItem(item.id));
  const onItemEdit = (item) => navigate(`/edit/item/${item.id}`);
  const onItemMerge = (targetItemId, sourceItemIds) => {
    dispatch(mergeItems({ targetItemId, sourceItemIds }));
  };
  const onItemFavorite = (item) => {
    const newItem = { id: item.id, isFavorite: !item.isFavorite };
    dispatch(updateItem({ item: newItem }));
  };
  const onItemHide = (item, refresh) => {
    if (item.visibility === 'hidden') {
      dispatch(updateItem({ refresh, item: { id: item.id, visibility: '' } }));
    } else if (item.visibility !== 'public') {
      dispatch(updateItem({ refresh, item: { id: item.id, visibility: 'hidden' } }));
    }
  };
  const onItemMarkReviewed = (item) => {
    const timeApproved = item.timeReviewed
      ? null : Math.round(Date.now() / 1000);
    const approvedItem = { id: item.id, timeReviewed: timeApproved };
    dispatch(updateItem({ item: approvedItem }));
  };
  const onItemAddToList = (item, newListInfo) => {
    if (!newListInfo) {
      return;
    }

    if (newListInfo.id) {
      dispatch(addItemsToList(
        { items: [{ id: item.id }], listId: newListInfo.id },
      ));
    } else {
      dispatch(createListInfo({ listInfo: newListInfo, itemId: item.id }));
    }
  };
  const onAuthorView = (aid) => navigate(`/${namespacePath}/author/${aid}`);
  const onTagOpen = (tag) => navigate(`/${namespacePath}/${tag}`);

  return (
    <>
      <Toolbar />
      { forceSignIn && <Login />}
      { !forceSignIn && (
        <StyledBackdrop open={!context}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      )}
      { !forceSignIn && context && (
        <Grid container>
          <Grid xs={1} item />
          <Grid xs={10} item>
            <TagCarousel tagActions={{ onTagOpen }} tags={tags} />
          </Grid>
          <Grid xs={1} item />
          <Grid xs={1} item />
          <Grid xs={10} item>
            <ItemCardGrid
              currentPage={context}
              isReadOnly={false}
              itemActions={{
                onAuthorView,
                onItemApprove: namespace === 'pending' ? onItemApprove : onItemMarkReviewed,
                ...(view === 'share' && { onItemHide: (item) => onItemHide(item, true) }),
                onItemClone,
                onItemOpen,
                onItemRead,
                onItemEdit,
                onItemFavorite,
                onItemAddToList,
                ...(showDelete && { onItemDelete }),
                ...(isPublic && { onItemBookmark }),
                onItemShare,
                onTagOpen,
                onItemMerge,
              }}
              listActions={{
                handlePreviousPage,
                handleNextPage,
              }}
              pageNumber={page}
            />
          </Grid>
          <Grid xs={1} item />
        </Grid>
      )}
    </>
  );
}

export default ItemsView;
