import { Box, styled } from '@mui/system';
import { Button, FormControlLabel, Grid } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Field, Form, Formik } from 'formik';
import { Autocomplete, CheckboxWithLabel, TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { mergePublicAndPrivateItems } from '../common/ItemTools';
import { buildCodeLanguageMap, languageCodes } from '../common/LanguageCodes';
import { updateListInfo } from '../redux/features/curatedSlice';
import ListInfoCard from './ListInfoCard';

const languageCodeMap = buildCodeLanguageMap();

const FormBox = styled(Box)({
  padding: '30px',
  width: '100%',
  height: '100%',
});

const GridCheckbox = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
});

const GridButtons = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-evenly',
  padding: '10px',
});

function getSeconds(date) {
  return Math.round(date.getTime() / 1000);
}

function mergeAuthors(origAuthors, newAuthorsStr) {
  if (!newAuthorsStr) {
    return origAuthors;
  }

  const newAuthors = newAuthorsStr.split(',').map((s) => s.trim());
  if (!origAuthors) {
    return newAuthors.map((name) => ({ name }));
  }

  const origAuthorsMap = origAuthors.reduce((map, obj) => {
    map[obj.name] = obj;
    return map;
  }, {});

  const result = [];
  newAuthors.forEach((author) => {
    if (origAuthors && origAuthorsMap[author]) {
      result.push(origAuthorsMap[author]);
    } else {
      result.push({ name: author });
    }
  });

  return result;
}

function diffStates(oldState, newState) {
  const diff = {};

  Object.keys(newState).forEach((key) => {
    if (oldState[key] instanceof Date || newState[key] instanceof Date) {
      if (+oldState[key] !== +newState[key] && newState[key]) {
        diff[key] = getSeconds(newState[key]);
      }
    } else if (oldState[key] instanceof Array
      || newState[key] instanceof Array) {
      if (oldState[key].join(',') !== newState[key].join(',')) {
        diff[key] = newState[key];
      }
    } else if (oldState[key] !== newState[key]) {
      diff[key] = newState[key];
    }
  });

  return diff;
}

function ListInfoForm(props) {
  const { listInfo, onListShare } = props;

  const listInfoCombined = mergePublicAndPrivateItems(listInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    title, description, coverImageUrl, isFavorite,
  } = listInfoCombined;

  const authors = listInfoCombined.authors
    && listInfoCombined.authors.map((a) => a.name).join(',');

  const lang = languageCodeMap[listInfoCombined.lang];

  const initialValues = {
    title,
    authors,
    description,
    coverImageUrl,
    isFavorite,
    lang,
  };

  return (
    <FormBox>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.title) {
            errors.title = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            const diff = diffStates(initialValues, values);
            if ('authors' in diff) {
              diff.authors = mergeAuthors(listInfo.authors, values.authors);
            }
            if (diff.lang) {
              diff.lang = values.lang.code;
            }
            if (Object.keys(diff).length > 0) {
              diff.id = listInfo.id;
              dispatch(updateListInfo(diff));
            }
          }, 500);
        }}
      >
        {({
          submitForm, isSubmitting, touched, errors,
        }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Form>
              <Grid spacing={5} xs={12} container>
                <Grid xs={12} item>
                  <Field
                    component={TextField}
                    label="Title"
                    name="title"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} item>
                  <Field
                    component={TextField}
                    label="Cover URL"
                    name="coverImageUrl"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid xs={4} item>
                  <Field
                    component={TextField}
                    label="Authors"
                    name="authors"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid lg={4} sm={6} xs={4} item>
                  <Field
                    component={Autocomplete}
                    getOptionLabel={(option) => option.name}
                    name="lang"
                    options={languageCodes}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        error={touched.lang && !!errors.lang}
                        helperText={touched.lang && errors.lang}
                        label="Language"
                      />
                    )}
                    fullWidth
                  />
                </Grid>
                <GridCheckbox xs={4} item>
                  <FormControlLabel
                    control={(
                      <Field
                        component={CheckboxWithLabel}
                        id="isFavorite"
                        name="isFavorite"
                        type="checkbox"
                      />
                    )}
                    label="Is Favorite?"
                  />
                </GridCheckbox>

                <Grid xs={12} item>
                  <Field
                    component={TextField}
                    label="Description"
                    name="description"
                    type="text"
                    fullWidth
                    multiline
                  />
                </Grid>
                <Grid xs={3} item />
                <Grid xs={6} item>
                  <ListInfoCard listInfo={listInfo} />
                </Grid>
                <Grid xs={3} item />
                <Grid xs={3} item />
                <GridButtons xs={6} container item>
                  <Button disabled={isSubmitting} onClick={submitForm}>
                    Save
                  </Button>
                  <Button disabled={isSubmitting} onClick={() => onListShare(listInfo)}>
                    Share
                  </Button>
                  <Button onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </GridButtons>
                <Grid xs={3} item />
              </Grid>
            </Form>
          </LocalizationProvider>
        )}
      </Formik>
    </FormBox>
  );
}

export default ListInfoForm;

ListInfoForm.propTypes = {
  listInfo: PropTypes.object.isRequired,
};
