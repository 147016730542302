import {
  Avatar, ButtonBase, Card, CardActions, CardContent, CardHeader, CardMedia,
  Chip, Collapse, IconButton, Link, Menu, MenuItem, Typography,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import CodeIcon from '@mui/icons-material/Code';
import CommentIcon from '@mui/icons-material/Comment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import LikeIcon from '@mui/icons-material/ThumbUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import amazonLogo from '../resources/amazon.svg';
import hnLogo from '../resources/hackernews.png';
import githubLogo from '../resources/github.svg';
import goodreadsLogo from '../resources/goodreads.svg';
import lobstersLogo from '../resources/lobsters.png';
import redditLogo from '../resources/reddit.png';
import slashdotLogo from '../resources/slashdot.png';
import twitterLogo from '../resources/twitter.png';
import wikipediaLogo from '../resources/wikipedia.png';
import youtubeLogo from '../resources/youtube.svg';
import * as ItemUtils from '../common/ItemTools';
import { buildMediaTypeNameMap } from '../common/MediaTypes';
import SelectListDialog from './SelectListDialog';

const typeNameMap = buildMediaTypeNameMap();

const StyledCard = styled(Card)((props) => ({
  ...(ItemUtils.getAuthorUrl(props.author)
    && { border: 'solid', borderColor: 'green' }),
  '&:hover': {
    boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.5)',
  },
}));

const StyledCardHeader = styled(CardHeader)({
  '& .MuiCardHeader-subheader': {
    textTransform: 'lowercase',
  },
});

const StyledCardMedia = styled(CardMedia)({
  cursor: 'pointer',
  height: '250px',
  paddingTop: '56.25%', // 16:9
  backgroundSize: 'contain',
});

const Title = styled(Typography)({
  fontFamily: 'Merriweather',
  marginTop: '5px',
  marginBottom: '10px',
  '&:hover': { cursor: 'pointer' },
});

const Excerpt = styled(Typography)({
  fontFamily: 'Merriweather',
  whiteSpace: 'pre-line',
  margin: '3px',
  '&:hover': { cursor: 'pointer' },
});

const StyledCollapse = styled(Collapse)({
  marginLeft: '15px',
});

const TagsBox = styled(Box)({
  flexGrow: 1,
});

const TagChip = styled(Chip)({
  margin: '2px',
});

const Discussion = styled(Avatar)({
  width: 22,
  height: 22,
  marginLeft: '3px',
});

const ActionsBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const ShareIconButton = styled(IconButton)(({ checked }) => checked && { backgroundColor: 'green' });

const StyledShareIcon = styled(ShareIcon)(({ checked }) => checked && { color: 'gold' });

const StyledDoneIcon = styled(DoneIcon)(({ checked }) => checked && { color: 'green' });

const StyledLikeIcon = styled(LikeIcon)(({ checked }) => checked && { color: 'red' });

const Score = styled(Avatar)((props) => {
  const { author, theme } = props;
  let style = {
    width: theme.spacing(4),
    height: theme.spacing(4),
  };
  const score = ItemUtils.calculateAuthorInfoScore(author);
  if (score === 100) {
    style = {
      ...style,
      backgroundColor: 'green',
      color: 'gold',
      fontSize: 'medium',
    };
  } else if (score > 60) {
    style.backgroundColor = 'orange';
  } else {
    style.backgroundColor = 'red';
  }
  return style;
});

const Expand = styled(ExpandMoreIcon)(({ expanded, theme }) => ({
  transform: (expanded ? 'rotate(180deg)' : 'rotate(0deg)'),
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function openCustomUrl(url) {
  window.open(url, '_blank');
}

function getLogoSource(domain) {
  if (domain.includes('ycombinator')) {
    return hnLogo;
  } if (domain.includes('lobste.rs')) {
    return lobstersLogo;
  } if (domain.includes('twitter.com')) {
    return twitterLogo;
  } if (domain.includes('slashdot.org')) {
    return slashdotLogo;
  } if (domain.includes('reddit.com')) {
    return redditLogo;
  } if (domain.includes('github.com')) {
    return githubLogo;
  } if (domain.includes('wikipedia.org')) {
    return wikipediaLogo;
  } if (domain.includes('goodreads.com')) {
    return goodreadsLogo;
  } if (domain.includes('youtube.com')) {
    return youtubeLogo;
  } if (domain.includes('amazon.com')) {
    return amazonLogo;
  }

  return null;
}

export default function AuthorCard(props) {
  const {
    author,
    isPreview,
    onAuthorApprove,
    onAuthorEdit,
    onAuthorDelete,
    onAuthorFavorite,
    onAuthorOpen,
    onAuthorAddToList,
    onAuthorRead,
    onAuthorShare,
    onTagOpen,
  } = props;

  const [menuAnchor, setMenuAnchor] = React.useState(null);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleListDialogClose = (listInfo) => {
    setDialogOpen(false);
    onAuthorAddToList(author, listInfo);
  };

  const menuOptions = [];
  let menuId = 0;
  if (onAuthorEdit) {
    menuOptions.push({ id: menuId += 1, label: 'Edit', action: onAuthorEdit });
  }
  if (onAuthorDelete) {
    menuOptions.push({ id: menuId += 1, label: 'Delete', action: onAuthorDelete });
  }
  if (onAuthorAddToList) {
    menuOptions.push({ id: menuId += 1, label: 'Add to list', action: () => setDialogOpen(true) });
  }

  const timePublishedMoment = author.timePublished
    ? `${ItemUtils.convertUnixTimeToMoment(author.timePublished)}`
    : null;
  const timeCreatedMoment = author.timeCreated
    ? `${ItemUtils.convertUnixTimeToMoment(author.timeCreated)}`
    : null;
  const timeCreatedReadable = ItemUtils.convertUnixTimeToReadable(author.timeCreated);
  let timeLeft = null;
  if (author.timeNeeded) {
    timeLeft = author.timeNeeded;
    if (author.timeSpent) {
      timeLeft = author.timeNeeded - author.timeSpent;
    }
  }
  const timeLeftMoment = timeLeft
    ? `${ItemUtils.humanizeIntervalInMinutes(timeLeft)}`
    : null;

  const subheader = (author.type ? `${typeNameMap[author.type]}, ` : '')
    + (timePublishedMoment || timeCreatedMoment)
    + (timeLeftMoment ? `, ${timeLeftMoment}` : '');
  const imageUrl = ItemUtils.getAuthorImageUrl(author);

  const menuOpen = Boolean(menuAnchor);
  const handleMoreActionsClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = (event, option, menuItem) => {
    if (option && option.action) {
      option.action(menuItem);
    }
    setMenuAnchor(null);
  };

  const logoUrl = ItemUtils.getLogoUrl(author);
  const sourceLetter = ItemUtils.getSourceLetter(author);
  const authorName = ItemUtils.getAuthorInfoName(author);

  return (
    <StyledCard author={author} raised>
      <StyledCardHeader
        action={(
          <ActionsBox>
            {!isPreview
              && (
              <Score author={author}>
                {ItemUtils.calculateAuthorInfoScore(author)}
              </Score>
              )}
            {menuOptions.length > 0
              && (
              <IconButton onClick={handleMoreActionsClick}>
                <MoreVertIcon />
              </IconButton>
              )}
          </ActionsBox>
        )}
        avatar={
          (logoUrl || sourceLetter)
          && (
          <Avatar src={logoUrl}>
            {sourceLetter}
          </Avatar>
          )
        }
        subheader={subheader}
        title={authorName}
      />
      <Link
        color="inherit"
        underline="none"
        onClick={() => onAuthorRead(author)}
      >
        <StyledCardMedia component="div" image={imageUrl} />
        <CardContent>
          <Title variant="h5">
            {authorName}
          </Title>
          {author.excerpt && (
            <Excerpt component="span" variant="body2">
              {author.excerpt}
            </Excerpt>
          )}
        </CardContent>
      </Link>
      <StyledCollapse in={expanded} timeout="auto" unmountOnExit>
        <div onClick={() => { navigator.clipboard.writeText(author.id); }}>
          <b>ID: </b>
          {author.id}
        </div>
        <div onClick={() => { onAuthorOpen(author); }}>
          <b>Recorded on: </b>
          {timeCreatedReadable}
        </div>
      </StyledCollapse>
      <CardActions disableSpacing>
        <div>
          {author.discussions && author.discussions.map((discussion) => {
            const domain = ItemUtils.getDomainFromUrl(discussion.url);
            const src = getLogoSource(domain);
            if (src) {
              return (
                <ButtonBase
                  key={discussion.url}
                  onClick={() => openCustomUrl(discussion.url)}
                >
                  <Discussion
                    src={src}
                    variant="rounded"
                  />
                </ButtonBase>
              );
            }
            return (
              <ButtonBase
                key={discussion.url}
                onClick={() => openCustomUrl(discussion.url)}
              >
                <Discussion variant="rounded">
                  <CommentIcon />
                </Discussion>
              </ButtonBase>
            );
          })}
        </div>
        <TagsBox>
          {author.tags && author.tags.map((data) => {
            let icon;
            if (data.name === 'coding') {
              icon = <CodeIcon />;
            }
            return (
              <TagChip
                icon={icon}
                key={data.name}
                label={data.name}
                variant="default"
                onClick={onTagOpen ? () => onTagOpen(data.name) : onTagOpen}
              />
            );
          })}
        </TagsBox>
        { onAuthorShare
          && (
          <ShareIconButton
            checked={author.visibility === 'public' ? 1 : 0}
            size="small"
            onClick={() => onAuthorShare(author)}
          >
            <StyledShareIcon checked={author.visibility === 'public' ? 1 : 0} />
          </ShareIconButton>
          )}
        { onAuthorApprove
          && (
          <IconButton size="small" onClick={() => onAuthorApprove(author)}>
            <StyledDoneIcon checked={author.timeReviewed ? 1 : 0} />
          </IconButton>
          )}
        { onAuthorFavorite
          && (
          <IconButton size="small" onClick={() => onAuthorFavorite(author)}>
            <StyledLikeIcon checked={author.isFavorite ? 1 : 0} />
          </IconButton>
          )}
        { !onAuthorFavorite && author.isFavorite
          && <StyledLikeIcon checked={1} />}
        <Expand
          expanded={expanded ? 1 : 0}
          size="small"
          onClick={handleExpandClick}
        />
      </CardActions>
      <Menu
        anchorEl={menuAnchor}
        id="more-menu"
        open={menuOpen}
        keepMounted
        onClose={handleMenuClose}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={(event) => handleMenuClose(event, option, author)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <SelectListDialog open={dialogOpen} onClose={handleListDialogClose} />
    </StyledCard>
  );
}

AuthorCard.propTypes = {
  isPreview: PropTypes.bool,
  onAuthorApprove: PropTypes.func,
  onAuthorEdit: PropTypes.func,
  onAuthorAddToList: PropTypes.func,
  onAuthorDelete: PropTypes.func,
  onAuthorFavorite: PropTypes.func,
  onAuthorOpen: PropTypes.func,
  onAuthorRead: PropTypes.func,
  onAuthorShare: PropTypes.func,
  onTagOpen: PropTypes.func,
};

AuthorCard.defaultProps = {
  isPreview: false,
  onAuthorApprove: null,
  onAuthorEdit: null,
  onAuthorAddToList: null,
  onAuthorDelete: null,
  onAuthorFavorite: null,
  onAuthorOpen: null,
  onAuthorRead: null,
  onAuthorShare: null,
  onTagOpen: null,
};
