import { Grid, Toolbar } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ListInfoCard from './ListInfoCard';
import PageStepper from './PageStepper';

function ListInfoCardGrid(props) {
  const {
    context,
    handleNextPage,
    handlePreviousPage,
    onListOpen,
    onListShare,
    onListApprove,
    onListDelete,
  } = props;

  const { pagesCount, pageNumber, lists } = context;

  return (
    <>
      <Toolbar />
      <Grid container>
        { lists.map((listInfo) => (
          <Grid key={listInfo.id} lg={4} sm={6} xs={12} item>
            <ListInfoCard {...{
              listInfo, onListOpen, onListShare, onListApprove, onListDelete,
            }}
            />
          </Grid>
        ))}
      </Grid>
      {pagesCount > 1 && (
        <PageStepper {...{
          pagesCount, pageNumber, handleNextPage, handlePreviousPage,
        }}
        />
      )}
    </>
  );
}

export default ListInfoCardGrid;

ListInfoCardGrid.propTypes = {
  context: PropTypes.object.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  handlePreviousPage: PropTypes.func.isRequired,
};
