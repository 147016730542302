import { CircularProgress, Grid, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ListInfoCardGrid from '../components/ListInfoCardGrid';
import {
  approveList, getNamespacePath, rejectList, selectContext, setContext, shareList,
} from '../redux/features/curatedSlice';
import { selectUserProfile } from '../redux/features/usersSlice';
import StyledBackdrop from '../components/StyledBackdrop';
import Login from '../components/Login';
import { mustSignIn } from '../common/ItemTools';

function contextUpdate(context, searchParams, view) {
  const params = new URLSearchParams(searchParams);
  const page = +params.get('page');
  const filter = params.get('filter');

  const contextParams = context
    ? {
      pageNumber: context.pageNumber,
      ...(context.filter && { filter: context.filter }),
      ...(context.view && { view: context.view }),
    } : {};

  const update = {
    pageNumber: page || 1,
    ...(filter && { filter }),
    ...(view && { view }),
  };

  if (JSON.stringify(contextParams) !== JSON.stringify(update)) {
    return update;
  }
}

function searchParamsUpdate(context, searchParams) {
  const update = context
    ? {
      ...(context.pageNumber && context.pageNumber !== 1 && { page: context.pageNumber }),
      ...(context.filter && { filter: context.filter }),
    } : {};

  const params = searchParams.toString();
  const result = new URLSearchParams(update).toString();
  if (params !== result) {
    return result;
  }

  return null;
}

function addPages(searchParams, increment) {
  const page = searchParams.get('page');
  const filter = searchParams.get('filter');

  const newPage = (page ? +page : 1) + increment;
  const params = {
    ...(newPage > 1 && { page: newPage }),
    ...(filter && { filter }),
  };

  return new URLSearchParams(params).toString();
}

function ListsView(props) {
  const { namespace, type: propType, view } = props;
  const type = propType || 'lists';

  const [searchParams] = useSearchParams();
  const page = +searchParams.get('page');
  const filter = searchParams.get('filter');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userProfile = useSelector(selectUserProfile);
  const status = useSelector((state) => state.curated.contextStatus);
  const currContext = useSelector(selectContext);

  const forceSignIn = mustSignIn(namespace, page, userProfile);
  const namespacePath = getNamespacePath(namespace);

  let context = null;
  if (!forceSignIn && currContext && currContext.type === type
    && currContext.namespace === namespace) {
    context = currContext;
  }

  useEffect(() => {
    if (forceSignIn || status === 'loading') {
      return;
    }
    const update = contextUpdate(context, searchParams, view);
    if (update) {
      const newContext = {
        ...(namespace && { namespace }),
        ...(view && { view }),
        type,
        ...update,
      };
      dispatch(setContext(newContext));
    }
  }, [filter, namespace, page, view, userProfile]);

  useEffect(() => {
    if (forceSignIn || status === 'loading') return;
    const update = searchParamsUpdate(context, searchParams);
    if (update !== null) { navigate({ search: update }); }
  }, [status]);

  const handleNextPage = () => {
    navigate({ search: addPages(searchParams, 1) });
  };

  const handlePreviousPage = () => {
    navigate({ search: addPages(searchParams, -1) });
  };

  const onListOpen = (list) => navigate(`/${namespacePath}/${list.id}`);
  const onListShare = (list) => { dispatch(shareList({ list })); };
  const onListApprove = (list) => { dispatch(approveList(list)); };
  const onListDelete = (item) => { dispatch(rejectList(item)); };

  return (
    <>
      <Toolbar />
      { forceSignIn && <Login />}
      { !forceSignIn && (
        <StyledBackdrop open={!context}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      )}
      { !forceSignIn && context && (
        <Grid container>
          <Grid xs={1} item />
          <Grid xs={10} item>
            <ListInfoCardGrid
              {...{
                context,
                onListOpen,
                handlePreviousPage,
                handleNextPage,
                ...(namespace !== 'pending' && { onListShare }),
                ...(namespace === 'pending' && { onListDelete }),
                ...(namespace === 'pending' && { onListApprove }),
              }}
            />
          </Grid>
          <Grid xs={1} item />
        </Grid>
      )}
    </>
  );
}

export default ListsView;
