import { Box, styled } from '@mui/system';
import {
  Button, CircularProgress, Dialog, DialogTitle, Divider, List, ListItem,
  ListItemButton, ListItemText, TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mergePublicAndPrivateItems } from '../common/ItemTools';
import { retrieveListInfoAll, selectAllUserLists } from '../redux/features/curatedSlice';

const NewListBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
});

const CreateButton = styled(Button)({
  marginTop: '10px',
});

function SelectListDialog(props) {
  const { onClose, open } = props;
  const newListRef = useRef();
  const findListRef = useRef();
  const dispatch = useDispatch();

  const status = useSelector((state) => state.curated.contextStatus);
  const allListInfos = useSelector(selectAllUserLists);
  useEffect(() => {
    if (!open || (allListInfos && allListInfos.length) || status === 'loading') return;
    dispatch(retrieveListInfoAll());
  }, [open]);

  const [newListName, setNewListName] = useState();
  const [listInfos, setListInfos] = useState(allListInfos);
  if (!listInfos.length && allListInfos.length) setListInfos(allListInfos);

  const handleFind = () => {
    const filter = findListRef.current.value;
    if (filter) {
      setListInfos(allListInfos.filter(
        (li) => li.privateData.title.toLowerCase().includes(filter.toLowerCase()),
      ));
    } else {
      setListInfos(allListInfos);
    }
  };

  const handleClose = () => {
    setListInfos(allListInfos);
    onClose();
    setNewListName(undefined);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleCreate = () => {
    const listInfo = { title: newListRef.current.value };
    onClose(listInfo);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select List</DialogTitle>
      <Divider />
      <List>
        <NewListBox>
          <TextField
            id="findList"
            inputRef={findListRef}
            label="Find List"
            onChange={() => handleFind()}
          />
        </NewListBox>
        {newListName && (
          <NewListBox>
            <TextField
              id="newListName"
              inputRef={newListRef}
              label="New List Name"
            />
            <CreateButton
              size="medium"
              onClick={() => handleCreate()}
            >
              Create
            </CreateButton>
          </NewListBox>
        )}
        { !newListName
          && (
          <ListItem button>
            <ListItemText
              primary="+ New List"
              onClick={() => setNewListName('New List')}
            />
          </ListItem>
          )}
        <Divider />
        {(!listInfos || !listInfos.length) && (
          <CircularProgress color="inherit" />
        )}
        {listInfos && listInfos.length && listInfos.map((listInfo) => (
          <ListItemButton
            key={listInfo.id}
            onClick={() => handleListItemClick(listInfo)}
          >
            <ListItemText primary={mergePublicAndPrivateItems(listInfo).title} />
          </ListItemButton>
        ))}
      </List>
    </Dialog>
  );
}

export default SelectListDialog;

SelectListDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

SelectListDialog.defaultProps = {
  open: false,
  onClose: null,
};
