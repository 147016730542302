import { Box, styled } from '@mui/system';
import {
  Button, createFilterOptions, Dialog, DialogActions,
  DialogContent, DialogTitle, Grid, MenuItem,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import MuiTextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {
  Field, FieldArray, Form, Formik, getIn,
} from 'formik';
import {
  Autocomplete, CheckboxWithLabel, TextField,
} from 'formik-mui';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  convertUnixTimeToDate, getAuthorName, getProfileName, mergeAuthorIds, mergePublicAndPrivateItems,
} from '../common/ItemTools';
import { buildCodeLanguageMap, languageCodes } from '../common/LanguageCodes';
import { mediaTypeLables } from '../common/MediaTypes';
import {
  createAuthor, fetchAuthorSuggestions, selectAllTagsPrivate,
  selectAllUserLists, selectAuthors, shareItem,
} from '../redux/features/curatedSlice';
import ItemCard from './ItemCard';
import ListInfoCard from './ListInfoCard';
import SelectListDialog from './SelectListDialog';
import { FormikDateTimePicker } from './FormikDateTimePicker';

const filter = createFilterOptions();

const languageCodeMap = buildCodeLanguageMap();

const RootForm = styled(Box)({
  padding: '30px',
  width: '100%',
  height: '100%',
});

const DiscussionBox = styled(Box)({
  display: 'flex',
  alignItems: 'flex-end',
});

const GridButtons = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-evenly',
  padding: '10px',
});

const GridLists = styled(Grid)({
  display: 'flex',
});

const VerticalDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
});

function getSeconds(date) {
  return Math.round(date.getTime() / 1000);
}

function diffStates(oldState, newState) {
  const diff = {};

  Object.keys(newState).forEach((key) => {
    if (oldState[key] instanceof Date || newState[key] instanceof Date) {
      if (+oldState[key] !== +newState[key] && newState[key]) {
        diff[key] = getSeconds(newState[key]);
      }
    } else if (Array.isArray(oldState[key])) {
      if (!(oldState[key].length === 0 && newState[key].length === 0)) {
        diff[key] = newState[key];
      }
    } else if (oldState[key] !== newState[key]) {
      diff[key] = newState[key];
    }
  });

  return diff;
}

function ItemForm(props) {
  const {
    onAuthorView,
    onSaveItem,
    onDeleteItem,
    onCancel,
    onItemAddToList,
    onItemRemoveFromList,
    disableValidation,
    item: propsItem,
  } = props;
  const item = propsItem || {};
  const onItemOpen = (openItem) => {
    window.open(openItem.sourceUrl, '_blank');
  };
  const itemActions = { onAuthorView, onItemOpen, onItemRead: onItemOpen };

  const privateData = item.privateData || {};
  const { publicData } = item;
  const combinedItem = mergePublicAndPrivateItems(item);

  const [dialogListOpen, setDialogListOpen] = useState(false);
  const handleListDialogClose = (listInfo, push) => {
    setDialogListOpen(false);
    onItemAddToList(item, listInfo);
    if (listInfo && listInfo.id) {
      push(listInfo);
    }
  };

  // TODO: improve! don't load _all_ lists, find based on item lists
  const allListInfos = useSelector(selectAllUserLists);
  let filteredListInfos;
  if (privateData.lists) {
    filteredListInfos = allListInfos.filter(
      (listInfo) => privateData.lists.includes(listInfo.id),
    );
  }

  const [newTagValue, setNewTagValue] = useState();
  const [dialogTagOpen, setTagDialogOpen] = useState(false);
  const handleTagDialogClose = () => {
    setTagDialogOpen(false);
  };

  const rawUserTags = useSelector(selectAllTagsPrivate);
  const userTags = rawUserTags.map((raw) => ({ name: raw }));

  const filterTagOptions = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}" tag`,
      });
    }

    return filtered;
  };

  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.users.authToken);

  const [authorOptions, setAuthorOptions] = useState([]);
  const previousController = useRef();
  const [newAuthorValue, setNewAuthorValue] = useState();
  const [dialogAuthorOpen, setAuthorDialogOpen] = useState(false);
  const handleAuthorDialogClose = () => {
    setAuthorDialogOpen(false);
  };

  const filterAuthorOptions = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '') {
      filtered.push({
        name: `Add "${params.inputValue}" author`,
        inputValue: params.inputValue,
      });
    }

    return filtered;
  };

  const updateAuthorSuggestions = async (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const { signal } = controller;
    previousController.current = controller;
    const result = await fetchAuthorSuggestions(userToken, searchTerm, signal);
    return result;
  };

  const createNewAuthor = async (newAuthor) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const { signal } = controller;
    previousController.current = controller;
    const result = await createAuthor(userToken, newAuthor, signal);
    return result;
  };

  const aids = mergeAuthorIds(item);
  const itemAuthors = useSelector((state) => selectAuthors(state, aids));

  const initialValues = {
    title: privateData.title,
    sourceUrl: privateData.sourceUrl,
    coverImageUrl: privateData.coverImageUrl,
    authors: itemAuthors.map((a) => ({ name: getAuthorName(a), authorId: a.id })),
    type: privateData.type || 0,
    lang: languageCodeMap[privateData.lang],
    excerpt: privateData.excerpt,
    timePublished: privateData.timePublished
      ? convertUnixTimeToDate(privateData.timePublished)
      : null,
    timeCreated: privateData.timeCreated
      ? convertUnixTimeToDate(privateData.timeCreated)
      : null,
    timeNeeded: privateData.timeNeeded,
    timeSpent: privateData.timeSpent,
    isFavorite: !!privateData.isFavorite,
    timeReviewed: privateData.timeReviewed
      ? convertUnixTimeToDate(privateData.timeReviewed)
      : null,
    discussions: privateData.discussions || [],
    tags: privateData.tags || [],
    relatedItems: privateData.relatedItems
      ? privateData.relatedItems.map((i) => i.id).join(',')
      : '',
    lists: filteredListInfos || [],
    hidden: privateData.visibility === 'hidden',
    domainMetadata: privateData.domainMetadata,
  };

  return (
    <RootForm>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (disableValidation) return errors;
          if (!values.type || values.type === 0) {
            errors.type = 'Required';
          }
          if (!values.title) {
            errors.title = 'Required';
          }
          return errors;
        }}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            /* eslint no-param-reassign: off */
            delete values.newTag;
            const diff = diffStates(initialValues, values);
            if ('hidden' in diff) {
              diff.visibility = diff.hidden ? 'hidden' : '';
              delete diff.hidden;
            }
            if ('authors' in diff) {
              diff.authorIds = diff.authors.map((an) => an.authorId);
              delete diff.authors;
            }
            if (diff.lang) {
              diff.lang = values.lang.code;
            }
            if ('relatedItems' in diff) {
              if (!diff.relatedItems) {
                diff.relatedItems = [];
              } else {
                diff.relatedItems = [...new Set(diff.relatedItems.split(',')
                  .map((i) => i.trim()))]
                  .map((id) => ({ id }));
              }
            }
            if (Object.keys(diff).length > 0) {
              if (item.id) {
                if (diff.lists) {
                  delete diff.lists;
                }
                diff.id = item.id;
                onSaveItem(diff);
              } else {
                onSaveItem(diff);
              }
            }
          }, 500);
        }}
      >
        {({
          submitForm, isSubmitting, touched, errors,
          values, handleChange, handleBlur, setFieldValue,
        }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Form>
              <Grid spacing={3} container>
                <Grid xs={12} item>
                  <Field
                    component={TextField}
                    label="Title"
                    name="title"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} item>
                  <Field
                    component={TextField}
                    label="URL"
                    name="sourceUrl"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} item>
                  <Field
                    component={TextField}
                    label="Excerpt"
                    name="excerpt"
                    type="text"
                    fullWidth
                    multiline
                  />
                </Grid>
                <Grid xs={12} item>
                  <Field
                    component={TextField}
                    label="Cover URL"
                    name="coverImageUrl"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} item>
                  <Field
                    component={Autocomplete}
                    filterOptions={filterAuthorOptions}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    name="authors"
                    options={authorOptions}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label="Authors"
                        placeholder="Add Author"
                      />
                    )}
                    multiple
                    onChange={(event, newValue) => {
                      const newAuthor = newValue.pop();
                      if (newAuthor && newAuthor.inputValue) {
                        setTimeout(() => {
                          setNewAuthorValue({
                            name: newAuthor.inputValue,
                          });
                          setAuthorDialogOpen(true);
                        });
                        return;
                      }
                      if (newAuthor) {
                        newValue.push(newAuthor);
                      }
                      setFieldValue('authors', newValue);
                    }}
                    onInputChange={
                      async (event, newValue) => {
                        let suggestions;
                        if (newValue) {
                          suggestions = await updateAuthorSuggestions(newValue);
                        } else {
                          setAuthorOptions([]);
                        }
                        if (suggestions) setAuthorOptions(suggestions);
                      }
                    }
                  />
                  <Dialog open={dialogAuthorOpen} onClose={handleAuthorDialogClose}>
                    <DialogTitle>Add a new author</DialogTitle>
                    <VerticalDialogContent>
                      <MuiTextField
                        id="name"
                        label="Name"
                        margin="dense"
                        type="text"
                        value={newAuthorValue && newAuthorValue.name}
                        autoFocus
                        onChange={(event) => setNewAuthorValue({
                          ...newAuthorValue,
                          ...{ name: event.target.value },
                        })}
                      />
                      <MuiTextField
                        id="url"
                        label="URL"
                        margin="dense"
                        type="text"
                        value={newAuthorValue && newAuthorValue.url}
                        autoFocus
                        onChange={(event) => setNewAuthorValue({
                          ...newAuthorValue,
                          ...{ url: event.target.value },
                        })}
                      />
                      <MuiTextField
                        id="imageUrl"
                        label="Image URL"
                        margin="dense"
                        type="text"
                        value={newAuthorValue && newAuthorValue.imageUrl}
                        autoFocus
                        onChange={(event) => setNewAuthorValue({
                          ...newAuthorValue,
                          ...{ imageUrl: event.target.value },
                        })}
                      />
                      <MuiTextField
                        id="username"
                        label="Username"
                        margin="dense"
                        type="text"
                        value={newAuthorValue && newAuthorValue.username}
                        autoFocus
                        onChange={(event) => setNewAuthorValue({
                          ...newAuthorValue,
                          ...{ username: event.target.value },
                        })}
                      />
                    </VerticalDialogContent>
                    <DialogActions>
                      <Button onClick={handleAuthorDialogClose}>
                        Cancel
                      </Button>
                      <Button
                        onClick={async (event) => {
                          event.preventDefault();
                          const newName = getProfileName(newAuthorValue);
                          if (!newName) return;

                          const newAuthor = await createNewAuthor(
                            { profiles: [newAuthorValue] },
                          );
                          const newAuthors = values.authors.concat(
                            [{ name: getAuthorName(newAuthor), authorId: newAuthor.id }],
                          );
                          setFieldValue('authors', newAuthors);
                          handleAuthorDialogClose();
                        }}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid lg={6} sm={6} xs={12} item>
                  <Field
                    component={TextField}
                    helperText="Please select media type"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Type"
                    name="type"
                    type="text"
                    value={values.type}
                    fullWidth
                    select
                  >
                    {mediaTypeLables.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid lg={6} sm={6} xs={12} item>
                  <Field
                    component={Autocomplete}
                    getOptionLabel={(option) => option.name}
                    name="lang"
                    options={languageCodes}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        error={touched.lang && !!errors.lang}
                        helperText={touched.lang && errors.lang}
                        label="Language"
                      />
                    )}
                    fullWidth
                  />
                </Grid>
                <Grid lg={4} sm={6} xs={12} item>
                  <Field
                    component={FormikDateTimePicker}
                    format="yyyy/MM/dd"
                    label="Date Published"
                    name="timePublished"
                    sx={{ display: 'flex' }}
                  />
                </Grid>
                <Grid lg={4} sm={6} xs={12} item>
                  <Field
                    component={FormikDateTimePicker}
                    format="yyyy/MM/dd"
                    label="Date Created"
                    name="timeCreated"
                    sx={{ display: 'flex' }}
                  />
                </Grid>
                <Grid lg={4} sm={6} xs={12} item>
                  <Field
                    component={TextField}
                    label="Time Needed (minutes)"
                    name="timeNeeded"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} item>
                  <Field
                    component={Autocomplete}
                    filterOptions={filterTagOptions}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    name="tags"
                    options={userTags}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label="Tags"
                        placeholder="Add Tags"
                      />
                    )}
                    multiple
                    onChange={(event, newValue) => {
                      const newTag = newValue.pop();
                      if (newTag && newTag.inputValue) {
                        setTimeout(() => {
                          setTagDialogOpen(true);
                          setNewTagValue({
                            name: newTag.inputValue,
                          });
                        });
                        return;
                      }

                      if (newTag) {
                        newValue.push(newTag);
                      }
                      setFieldValue('tags', newValue);
                    }}
                  />
                  <Dialog open={dialogTagOpen} onClose={handleTagDialogClose}>
                    <DialogTitle>Add a new tag</DialogTitle>
                    <DialogContent>
                      <MuiTextField
                        id="name"
                        label="tag name"
                        margin="dense"
                        type="text"
                        value={newTagValue && newTagValue.name}
                        autoFocus
                        onChange={(event) => setNewTagValue({ name: event.target.value })}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleTagDialogClose}>
                        Cancel
                      </Button>
                      <Button
                        onClick={(event) => {
                          event.preventDefault();
                          const newTags = values.tags.concat([newTagValue]);
                          setFieldValue('tags', newTags);
                          handleTagDialogClose();
                        }}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid xs={12} item>
                  <FieldArray name="discussions" fullWidth>
                    {(arrayHelpers) => (
                      <div>
                        {values.discussions.map((d, index) => {
                          const name = `discussions.${index}.url`;
                          const { remove } = arrayHelpers;
                          const touchedUrl = getIn(touched, name);
                          const errorUrl = getIn(errors, name);

                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <DiscussionBox key={index}>
                              <MuiTextField
                                error={Boolean(touchedUrl && errorUrl)}
                                helperText={touchedUrl && errorUrl ? errorUrl : ''}
                                label="Discussion URL"
                                margin="normal"
                                name={name}
                                value={d.url}
                                fullWidth
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <Button
                                margin="normal"
                                type="button"
                                variant="text"
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon />
                              </Button>
                            </DiscussionBox>
                          );
                        })}
                        <Button onClick={() => arrayHelpers.push({ url: '' })}>
                          Add Discussion URL
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Grid>
                <Grid lg={4} sm={6} xs={12} item>
                  <Field
                    component={TextField}
                    label="Domain Name"
                    name="domainMetadata.name"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid lg={4} sm={6} xs={12} item>
                  <Field
                    component={TextField}
                    label="Domain Logo"
                    name="domainMetadata.logo"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid lg={4} sm={6} xs={12} item>
                  <Field
                    component={TextField}
                    label="Domain Logo Greyscale"
                    name="domainMetadata.greyscale_logo"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid lg={4} sm={6} xs={12} item>
                  <Field
                    component={FormikDateTimePicker}
                    label="Date Reviewed"
                    name="timeReviewed"
                    sx={{ display: 'flex' }}
                  />
                </Grid>
                <Grid lg={4} sm={6} xs={12} item>
                  <Field
                    component={TextField}
                    label="Time Spent (minutes)"
                    name="timeSpent"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid lg={2} sm={6} xs={12} item>
                  <Field
                    component={CheckboxWithLabel}
                    id="hidden"
                    Label={{ label: 'Hidden' }}
                    name="hidden"
                    type="checkbox"
                  />
                </Grid>
                <Grid lg={2} sm={6} xs={12} item>
                  <Field
                    component={CheckboxWithLabel}
                    id="isFavorite"
                    Label={{ label: 'Favorite' }}
                    name="isFavorite"
                    type="checkbox"
                  />
                </Grid>
                {combinedItem && publicData
                  && (
                  <>
                    <Grid lg={2} sm={1} xs={false} item />
                    <Grid lg={4} sm={5} xs={12} item>
                      <ItemCard
                        item={combinedItem}
                        {...itemActions}
                      />
                    </Grid>
                    <Grid lg={4} sm={5} xs={12} item>
                      <ItemCard
                        item={publicData}
                        isPreview
                        {...itemActions}
                      />
                    </Grid>
                    <Grid lg={2} sm={1} xs={false} item />
                  </>
                  )}
                {combinedItem && !publicData
                  && (
                  <>
                    <Grid lg={4} sm={3} xs={false} item />
                    <Grid lg={4} sm={6} xs={12} item>
                      <ItemCard
                        item={combinedItem}
                        {...itemActions}
                      />
                    </Grid>
                    <Grid lg={4} sm={3} xs={false} item />
                  </>
                  )}
                <Grid lg={4} sm={2} xs={false} item />
                <GridButtons lg={4} sm={8} xs={12} item>
                  <Button disabled={isSubmitting} onClick={submitForm}>
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(shareItem({ item }));
                    }}
                  >
                    Share
                  </Button>
                  <Button onClick={onCancel}>
                    Cancel
                  </Button>
                  {onDeleteItem
                  && (
                    <Button onClick={() => onDeleteItem(item)}>
                      Delete
                    </Button>
                  )}
                </GridButtons>
                <Grid lg={4} sm={2} xs={false} item />
                <Grid xs={12} item>
                  <FieldArray name="lists" fullWidth>
                    {({ push, remove }) => (
                      <div>
                        <SelectListDialog
                          open={dialogListOpen}
                          onClose={
                            (listInfo) => handleListDialogClose(listInfo, push)
                          }
                        />
                        <GridLists
                          spacing={5}
                          container
                        >
                          {values.lists.map((listInfo, index) => (
                            <Grid key={listInfo.id} xs={6} item>
                              <ListInfoCard
                                listInfo={listInfo}
                              />
                              <Button
                                onClick={() => {
                                  onItemRemoveFromList(item, listInfo);
                                  remove(index);
                                }}
                              >
                                <LinkOffIcon />
                              </Button>
                            </Grid>
                          ))}
                        </GridLists>
                        <Button onClick={() => setDialogListOpen(true)}>
                          Add To List
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Grid>
                <Grid xs={12} item>
                  <Field
                    component={TextField}
                    label="Related Items"
                    name="relatedItems"
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Form>
          </LocalizationProvider>
        )}
      </Formik>
    </RootForm>
  );
}

export default ItemForm;

ItemForm.propTypes = {
  onSaveItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onItemAddToList: PropTypes.func,
  onItemRemoveFromList: PropTypes.func,
};

ItemForm.defaultProps = {
  onSaveItem: null,
  onDeleteItem: null,
  onItemAddToList: null,
  onItemRemoveFromList: null,
};
