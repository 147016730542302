import { CircularProgress, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ListInfoForm from '../components/ListInfoForm';
import { selectContext, setContext, shareList } from '../redux/features/curatedSlice';
import { selectUserProfile } from '../redux/features/usersSlice';
import StyledBackdrop from '../components/StyledBackdrop';
import Login from '../components/Login';

function ListUpdateView() {
  const { listId } = useParams();

  const dispatch = useDispatch();
  const context = useSelector(selectContext);
  const userProfile = useSelector(selectUserProfile);

  useEffect(() => {
    if (userProfile && listId) dispatch(setContext({ type: 'list', listId, refresh: true }));
  }, [listId, userProfile]);

  const listInfo = context && context.listInfo;

  const onListShare = (list) => { dispatch(shareList({ list })); };

  return (
    <>
      <Toolbar />
      { !userProfile && <Login />}
      { userProfile && !listInfo && (
        <StyledBackdrop open={!listInfo}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      )}
      { userProfile && listInfo && (
        <ListInfoForm {...{ listInfo, onListShare }} />
      )}
    </>
  );
}

export default ListUpdateView;
