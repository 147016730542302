import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

const initialState = {
  status: 'idle',
  authToken: null,
  error: null,
};

export const fetchUserInfo = createAsyncThunk(
  'users/fetchUserInfo',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      return;
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/users/me`,
      {
        headers: { Authorization: `${userToken.token_type} ${userToken.id_token}` },
      },
    );
    const json = await res.json();
    return json;
  },
);

export const updateUserInfo = createAsyncThunk(
  'users/updateUserInfo',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      return;
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/users/me`,
      {
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const json = await res.json();
    return json;
  },
);

function parseUserInfo(state, payload) {
  Object.keys(payload).forEach((key) => {
    state[key] = payload[key];
  });
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateAuthToken(state, action) {
      state.authToken = action.payload.authToken;
      state.authProfile = action.payload.authProfile;
    },
    logOut(state) {
      return state;
    },
    setDarkMode(state, action) {
      state.darkMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        parseUserInfo(state, action.payload);
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateUserInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUserInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        parseUserInfo(state, action.payload);
      })
      .addCase(updateUserInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default usersSlice.reducer;

export const {
  updateAuthToken, logOut, setDarkMode, setAnyLanguage,
} = usersSlice.actions;

export const selectUserProfile = (state) => state.users.authProfile;
