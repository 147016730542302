import { styled } from '@mui/system';
import {
  Grid, Toolbar, Typography,
} from '@mui/material';
import React from 'react';
import largeLogo from '../resources/500x500.png';

const GridRoot = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
});

const Title = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1),
  textAlign: 'center',
  lineHeight: '1.5em',
  border: theme.spacing(1),
}));

const LogoImg = styled('img')({
  width: '250px',
  margin: '20px',
});

function Login() {
  return (
    <>
      <Toolbar />
      <GridRoot
        alignItems="center"
        direction="column"
        justify="center"
        container
      >
        <LogoImg
          alt="logo"
          src={largeLogo}
        />
        <Title component="p" variant="h4" paragraph>
          Please sign in.
        </Title>
      </GridRoot>
    </>
  );
}

export default Login;
