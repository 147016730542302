import { combineReducers, configureStore } from '@reduxjs/toolkit';

import usersReducer from './features/usersSlice';
import snackbarReducer from './features/snackbarSlice';
import curatedReducer from './features/curatedSlice';

const combinedReducer = combineReducers({
  users: usersReducer,
  snackbar: snackbarReducer,
  curated: curatedReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'users/logOut') {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
});
