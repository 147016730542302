export const languageCodes = [
  { code: 'af', name: 'Afrikaans' },
  { code: 'sq', name: 'Albanian' },
  { code: 'an', name: 'Aragonese' },
  { code: 'ar', name: 'Arabic (Standard)' },
  { code: 'ar-dz', name: 'Arabic (Algeria)' },
  { code: 'ar-bh', name: 'Arabic (Bahrain)' },
  { code: 'ar-eg', name: 'Arabic (Egypt)' },
  { code: 'ar-iq', name: 'Arabic (Iraq)' },
  { code: 'ar-jo', name: 'Arabic (Jordan)' },
  { code: 'ar-kw', name: 'Arabic (Kuwait)' },
  { code: 'ar-lb', name: 'Arabic (Lebanon)' },
  { code: 'ar-ly', name: 'Arabic (Libya)' },
  { code: 'ar-ma', name: 'Arabic (Morocco)' },
  { code: 'ar-om', name: 'Arabic (Oman)' },
  { code: 'ar-qa', name: 'Arabic (Qatar)' },
  { code: 'ar-sa', name: 'Arabic (Saudi Arabia)' },
  { code: 'ar-sy', name: 'Arabic (Syria)' },
  { code: 'ar-tn', name: 'Arabic (Tunisia)' },
  { code: 'ar-ae', name: 'Arabic (U.A.E.)' },
  { code: 'ar-ye', name: 'Arabic (Yemen)' },
  { code: 'hy', name: 'Armenian' },
  { code: 'as', name: 'Assamese' },
  { code: 'ast', name: 'Asturian' },
  { code: 'az', name: 'Azerbaijani' },
  { code: 'eu', name: 'Basque' },
  { code: 'bg', name: 'Bulgarian' },
  { code: 'be', name: 'Belarusian' },
  { code: 'bn', name: 'Bengali' },
  { code: 'bs', name: 'Bosnian' },
  { code: 'br', name: 'Breton' },
  { code: 'my', name: 'Burmese' },
  { code: 'ca', name: 'Catalan' },
  { code: 'ch', name: 'Chamorro' },
  { code: 'ce', name: 'Chechen' },
  { code: 'zh', name: 'Chinese' },
  { code: 'zh-hk', name: 'Chinese (Hong Kong)' },
  { code: 'zh-cn', name: 'Chinese (PRC)' },
  { code: 'zh-sg', name: 'Chinese (Singapore)' },
  { code: 'zh-tw', name: 'Chinese (Taiwan)' },
  { code: 'cv', name: 'Chuvash' },
  { code: 'co', name: 'Corsican' },
  { code: 'cr', name: 'Cree' },
  { code: 'hr', name: 'Croatian' },
  { code: 'cs', name: 'Czech' },
  { code: 'da', name: 'Danish' },
  { code: 'nl', name: 'Dutch (Standard)' },
  { code: 'nl-be', name: 'Dutch (Belgian)' },
  { code: 'en', name: 'English' },
  { code: 'en-au', name: 'English (Australia)' },
  { code: 'en-bz', name: 'English (Belize)' },
  { code: 'en-ca', name: 'English (Canada)' },
  { code: 'en-ie', name: 'English (Ireland)' },
  { code: 'en-jm', name: 'English (Jamaica)' },
  { code: 'en-nz', name: 'English (New Zealand)' },
  { code: 'en-ph', name: 'English (Philippines)' },
  { code: 'en-za', name: 'English (South Africa)' },
  { code: 'en-tt', name: 'English (Trinidad & Tobago)' },
  { code: 'en-gb', name: 'English (United Kingdom)' },
  { code: 'en-us', name: 'English (United States)' },
  { code: 'en-zw', name: 'English (Zimbabwe)' },
  { code: 'eo', name: 'Esperanto' },
  { code: 'et', name: 'Estonian' },
  { code: 'fo', name: 'Faeroese' },
  { code: 'fa', name: 'Persian' },
  { code: 'fj', name: 'Fijian' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fr', name: 'French (Standard)' },
  { code: 'fr-be', name: 'French (Belgium)' },
  { code: 'fr-ca', name: 'French (Canada)' },
  { code: 'fr-fr', name: 'French (France)' },
  { code: 'fr-lu', name: 'French (Luxembourg)' },
  { code: 'fr-mc', name: 'French (Monaco)' },
  { code: 'fr-ch', name: 'French (Switzerland)' },
  { code: 'fy', name: 'Frisian' },
  { code: 'fur', name: 'Friulian' },
  { code: 'gd', name: 'Scots Gaelic' },
  { code: 'gd-ie', name: 'Gaelic (Irish)' },
  { code: 'gl', name: 'Galacian' },
  { code: 'ka', name: 'Georgian' },
  { code: 'de', name: 'German (Standard)' },
  { code: 'de-at', name: 'German (Austria)' },
  { code: 'de-de', name: 'German (Germany)' },
  { code: 'de-li', name: 'German (Liechtenstein)' },
  { code: 'de-lu', name: 'German (Luxembourg)' },
  { code: 'de-ch', name: 'German (Switzerland)' },
  { code: 'el', name: 'Greek' },
  { code: 'gu', name: 'Gujurati' },
  { code: 'ht', name: 'Haitian' },
  { code: 'he', name: 'Hebrew' },
  { code: 'hi', name: 'Hindi' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'is', name: 'Icelandic' },
  { code: 'id', name: 'Indonesian' },
  { code: 'iu', name: 'Inuktitut' },
  { code: 'ga', name: 'Irish' },
  { code: 'it', name: 'Italian (Standard)' },
  { code: 'it-ch', name: 'Italian (Switzerland)' },
  { code: 'ja', name: 'Japanese' },
  { code: 'kn', name: 'Kannada' },
  { code: 'ks', name: 'Kashmiri' },
  { code: 'kk', name: 'Kazakh' },
  { code: 'km', name: 'Khmer' },
  { code: 'ky', name: 'Kirghiz' },
  { code: 'tlh', name: 'Klingon' },
  { code: 'ko', name: 'Korean' },
  { code: 'ko-kp', name: 'Korean (North Korea)' },
  { code: 'ko-kr', name: 'Korean (South Korea)' },
  { code: 'la', name: 'Latin' },
  { code: 'lv', name: 'Latvian' },
  { code: 'lt', name: 'Lithuanian' },
  { code: 'lb', name: 'Luxembourgish' },
  { code: 'mk', name: 'FYRO Macedonian' },
  { code: 'ms', name: 'Malay' },
  { code: 'ml', name: 'Malayalam' },
  { code: 'mt', name: 'Maltese' },
  { code: 'mi', name: 'Maori' },
  { code: 'mr', name: 'Marathi' },
  { code: 'mo', name: 'Moldavian' },
  { code: 'nv', name: 'Navajo' },
  { code: 'ng', name: 'Ndonga' },
  { code: 'ne', name: 'Nepali' },
  { code: 'no', name: 'Norwegian' },
  { code: 'nb', name: 'Norwegian (Bokmal)' },
  { code: 'nn', name: 'Norwegian (Nynorsk)' },
  { code: 'oc', name: 'Occitan' },
  { code: 'or', name: 'Oriya' },
  { code: 'om', name: 'Oromo' },
  { code: 'fa-ir', name: 'Persian/Iran' },
  { code: 'pl', name: 'Polish' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'pt-br', name: 'Portuguese (Brazil)' },
  { code: 'pa', name: 'Punjabi' },
  { code: 'pa-in', name: 'Punjabi (India)' },
  { code: 'pa-pk', name: 'Punjabi (Pakistan)' },
  { code: 'qu', name: 'Quechua' },
  { code: 'rm', name: 'Rhaeto-Romanic' },
  { code: 'ro', name: 'Romanian' },
  { code: 'ro-mo', name: 'Romanian (Moldavia)' },
  { code: 'ru', name: 'Russian' },
  { code: 'ru-mo', name: 'Russian (Moldavia)' },
  { code: 'sz', name: 'Sami (Lappish)' },
  { code: 'sg', name: 'Sango' },
  { code: 'sa', name: 'Sanskrit' },
  { code: 'sc', name: 'Sardinian' },
  { code: 'sd', name: 'Sindhi' },
  { code: 'si', name: 'Singhalese' },
  { code: 'sr', name: 'Serbian' },
  { code: 'sk', name: 'Slovak' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'so', name: 'Somani' },
  { code: 'sb', name: 'Sorbian' },
  { code: 'es', name: 'Spanish' },
  { code: 'es-ar', name: 'Spanish (Argentina)' },
  { code: 'es-bo', name: 'Spanish (Bolivia)' },
  { code: 'es-cl', name: 'Spanish (Chile)' },
  { code: 'es-co', name: 'Spanish (Colombia)' },
  { code: 'es-cr', name: 'Spanish (Costa Rica)' },
  { code: 'es-do', name: 'Spanish (Dominican Republic)' },
  { code: 'es-ec', name: 'Spanish (Ecuador)' },
  { code: 'es-sv', name: 'Spanish (El Salvador)' },
  { code: 'es-gt', name: 'Spanish (Guatemala)' },
  { code: 'es-hn', name: 'Spanish (Honduras)' },
  { code: 'es-mx', name: 'Spanish (Mexico)' },
  { code: 'es-ni', name: 'Spanish (Nicaragua)' },
  { code: 'es-pa', name: 'Spanish (Panama)' },
  { code: 'es-py', name: 'Spanish (Paraguay)' },
  { code: 'es-pe', name: 'Spanish (Peru)' },
  { code: 'es-pr', name: 'Spanish (Puerto Rico)' },
  { code: 'es-es', name: 'Spanish (Spain)' },
  { code: 'es-uy', name: 'Spanish (Uruguay)' },
  { code: 'es-ve', name: 'Spanish (Venezuela)' },
  { code: 'sx', name: 'Sutu' },
  { code: 'sw', name: 'Swahili' },
  { code: 'sv', name: 'Swedish' },
  { code: 'sv-fi', name: 'Swedish (Finland)' },
  { code: 'sv-sv', name: 'Swedish (Sweden)' },
  { code: 'ta', name: 'Tamil' },
  { code: 'tt', name: 'Tatar' },
  { code: 'te', name: 'Teluga' },
  { code: 'th', name: 'Thai' },
  { code: 'tig', name: 'Tigre' },
  { code: 'ts', name: 'Tsonga' },
  { code: 'tn', name: 'Tswana' },
  { code: 'tr', name: 'Turkish' },
  { code: 'tk', name: 'Turkmen' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'hsb', name: 'Upper Sorbian' },
  { code: 'ur', name: 'Urdu' },
  { code: 've', name: 'Venda' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'vo', name: 'Volapuk' },
  { code: 'wa', name: 'Walloon' },
  { code: 'cy', name: 'Welsh' },
  { code: 'xh', name: 'Xhosa' },
  { code: 'ji', name: 'Yiddish' },
  { code: 'zu', name: 'Zulu' },
];

export function buildCodeLanguageMap() {
  const result = {};
  languageCodes.forEach((langCode) => {
    result[langCode.code] = langCode;
  });
  return result;
}
