import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import PageStepper from './PageStepper';
import AuthorCard from './AuthorCard';
import { mergePublicAndPrivateAuthors } from '../common/ItemTools';

export default function AuthorCardGrid(props) {
  const {
    currentPage,
    authorActions,
    listActions,
  } = props;

  const { handleNextPage, handlePreviousPage } = listActions;

  const {
    pagesCount, pageNumber, authors, namespace,
  } = currentPage || {};

  return (
    <>
      {authors && authors.length > 0
        && (
        <Grid spacing={2} container>
          {
            authors.map((author) => mergePublicAndPrivateAuthors(author)).map(
              (author) => (
                <Grid key={author.id} lg={3} sm={6} xs={12} item>
                  <AuthorCard
                    author={author}
                    isPreview={namespace === 'public'}
                    key={author.id}
                    {...authorActions}
                  />
                </Grid>
              ),
            )
          }
        </Grid>
        )}
      {pagesCount > 1 && (
        <PageStepper {...{
          pagesCount, pageNumber, handleNextPage, handlePreviousPage,
        }}
        />
      )}
    </>
  );
}

AuthorCardGrid.propTypes = {
  currentPage: PropTypes.object.isRequired,
  authorActions: PropTypes.object.isRequired,
  listActions: PropTypes.object.isRequired,
};
