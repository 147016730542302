import { CircularProgress, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ItemForm from '../components/ItemForm';
import {
  addItemsToList, createItem, createListInfo, deleteItem, removeItemFromList,
  selectContext, setContext, updateItem,
} from '../redux/features/curatedSlice';
import { setSnackbar } from '../redux/features/snackbarSlice';
import StyledBackdrop from '../components/StyledBackdrop';

export default function ItemUpdateView() {
  const { itemId, listId } = useParams();

  const itemCreateOrUpdate = itemId ? updateItem : createItem;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useSelector(selectContext);
  const listInfoStatus = useSelector((state) => state.curated.listInfoStatus);

  useEffect(() => {
    if (itemId) {
      dispatch(setContext({
        type: 'item',
        itemId,
        ...(listId && { listId }),
      }));
    }
  }, [itemId, listId]);

  const item = itemId ? context && context.item : {};
  // post-create state -> redirect
  if (context && context.item && !itemId) {
    navigate(`/edit/item/${context.item.id}`, { replace: true });
  }

  const contextListInfo = listId && context && context.listInfo;

  const onAuthorView = (aid) => {
    navigate(`/learn/author/${aid}`);
  };

  const onSaveItem = (newItem) => {
    dispatch(itemCreateOrUpdate({
      item: newItem,
      ...(contextListInfo && !contextListInfo.autoGenerated
        && { listId: contextListInfo.id }),
    }))
      .unwrap()
      .then(() => {
        dispatch(setSnackbar({
          snackbarOpen: true,
          snackbarMessage: 'Item saved.',
        }));
      })
      .catch(() => {
        dispatch(setSnackbar({
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Failed to save the item.',
        }));
      });
  };

  const onItemAddToList = (newItem, listInfo) => {
    if (!listInfo) {
      return;
    }
    if (listInfo.id) {
      dispatch(addItemsToList(
        { items: [{ id: newItem.id }], listId: listInfo.id },
      ));
    } else {
      dispatch(createListInfo({ listInfo, itemId: newItem.id }));
    }
  };

  const onItemRemoveFromList = (newItem, listInfo) => {
    if (!listInfo || !newItem) {
      return;
    }
    if (listInfo.id && newItem.id) {
      dispatch(removeItemFromList(
        { listId: listInfo.id, itemId: newItem.id },
      ));
    }
  };

  const onDeleteItem = (itemToDelete) => {
    dispatch(deleteItem({ item: itemToDelete }));
    navigate(-1);
  };

  const onCancel = () => navigate(-1);

  const [backdropOpen, setOpen] = React.useState(!item);
  const newOpen = !item;
  if (newOpen !== backdropOpen) {
    setOpen(newOpen);
  }

  const itemActions = {
    onAuthorView, onCancel, onDeleteItem, onItemAddToList, onItemRemoveFromList, onSaveItem,
  };

  return (
    <>
      <Toolbar />
      <StyledBackdrop open={!item || !!(listId && listInfoStatus === 'loading')}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
      { item && (
        <ItemForm
          item={item}
          {...itemActions}
        />
      )}
    </>
  );
}
