import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

const PAGE_SIZE = 8;
const PAGE_SIZE_LISTS = 12;

const itemsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const authorsAdapter = createEntityAdapter({
  selectId: (author) => author.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const itemsPendingAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const authorsPendingAdapter = createEntityAdapter({
  selectId: (author) => author.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const listsAdapter = createEntityAdapter({
  selectId: (listInfo) => listInfo.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const listsPendingAdapter = createEntityAdapter({
  selectId: (listInfo) => listInfo.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const tagsPrivateAdapter = createEntityAdapter({
  selectId: (tag) => tag,
});

const tagsPublicAdapter = createEntityAdapter({
  selectId: (tag) => tag,
});

const initialState = {
  status: 'idle',
  error: null,
  context: null,
  items: itemsAdapter.getInitialState(),
  authors: authorsAdapter.getInitialState(),
  itemsPending: itemsPendingAdapter.getInitialState(),
  authorsPending: authorsPendingAdapter.getInitialState(),
  lists: listsAdapter.getInitialState(),
  listsPending: listsPendingAdapter.getInitialState(),
  tagsPrivate: tagsPrivateAdapter.getInitialState(),
  tagsPublic: tagsPublicAdapter.getInitialState(),
};

function getUrlPrefix(namespace) {
  if (namespace) {
    return `${process.env.REACT_APP_API_URL}/${namespace}`;
  }

  return `${process.env.REACT_APP_API_URL}/private`;
}

function getUrlPrefixListInfo(namespace) {
  if (namespace) {
    return `${process.env.REACT_APP_API_URL}/${namespace}/lists`;
  }

  return `${process.env.REACT_APP_API_URL}/lists`;
}

function preserveContextBase(context) {
  const {
    namespace, filter, pageNumber, itemId, listId, view, type,
  } = context;
  const baseContext = {
    ...(type && { type }),
    ...(namespace && { namespace }),
    ...(filter && { filter }),
    ...(pageNumber && { pageNumber }),
    ...(listId && { listId }),
    ...(itemId && { itemId }),
    ...(view && { view }),
  };
  return baseContext;
}

export function getNamespacePath(namespace) {
  let namespacePath;
  switch (namespace) {
    case 'public':
      namespacePath = 'explore';
      break;
    case 'pending':
      namespacePath = 'curate';
      break;
    default:
      namespacePath = 'learn';
      break;
  }
  return namespacePath;
}

export async function fetchAuthorSuggestions(userToken, searchTerm, signal) {
  if (!userToken) {
    throw new Error('Unauthenticated caller');
  }
  const url = new URL(`${getUrlPrefix()}/authors/suggest`);
  const searchParams = new URLSearchParams();
  searchParams.append('filter', searchTerm);
  url.search = searchParams.toString();
  const res = await fetch(
    url,
    {
      signal,
      headers: {
        Authorization: `${userToken.token_type} ${userToken.id_token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
  );
  const json = await res.json();
  return json;
}

export async function createAuthor(userToken, newAuthor, signal) {
  if (!userToken) {
    throw new Error('Unauthenticated caller');
  }
  const url = new URL(`${getUrlPrefix()}/authors`);
  const res = await fetch(
    url,
    {
      signal,
      method: 'POST',
      body: JSON.stringify(newAuthor),
      headers: {
        Authorization: `${userToken.token_type} ${userToken.id_token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
  );
  const json = await res.json();
  return json;
}

export const retrieveListInfo = createAsyncThunk(
  'curated/retrieveListInfo',
  async ({ namespace, listId, refresh }, thunkAPI) => {
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    const res = await fetch(
      `${getUrlPrefixListInfo(namespace)}/${listId}`,
      {
        headers: {
          ...(userToken && { Authorization: `${userToken.token_type} ${userToken.id_token}` }),
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const listInfo = await res.json();
    return { listInfo, refresh };
  },
);

export const retrieveItemsList = createAsyncThunk(
  'curated/retrieveItemsList',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      listId, view, namespace, pageNumber,
    } = payload || {};

    const userToken = state.users.authToken;
    if (namespace !== 'public' && !userToken) {
      throw new Error('Unauthenticated caller');
    }
    const offset = ((pageNumber || 1) - 1) * PAGE_SIZE;

    const url = new URL(`${getUrlPrefix(namespace)}/items`);
    const searchParams = new URLSearchParams();
    searchParams.append('offset', offset);
    searchParams.append('limit', PAGE_SIZE);

    if (listId) {
      searchParams.append('list_id', listId);
    }
    if (view) {
      searchParams.append('view', view);
    }
    url.search = searchParams.toString();

    const res = await fetch(
      url,
      {
        method: 'GET',
        headers: {
          ...(userToken && { Authorization: `${userToken.token_type} ${userToken.id_token}` }),
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const itemRange = await res.json();
    if (listId) {
      thunkAPI.dispatch(retrieveListInfo(payload));
    }
    return { ...payload, pageNumber: pageNumber || 1, itemRange };
  },
);

export const retrieveItemsSearch = createAsyncThunk(
  'curated/retrieveItemsSearch',
  async (payload, thunkAPI) => {
    const {
      filter, listId, namespace, view,
    } = payload;
    const pageNumber = payload.pageNumber || 1;
    const offset = (pageNumber - 1) * PAGE_SIZE;

    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (namespace !== 'public' && !userToken) {
      throw new Error('Unauthenticated caller');
    }
    const url = new URL(`${getUrlPrefix(namespace)}/items`);
    const searchParams = new URLSearchParams();
    searchParams.append('filter', filter);
    searchParams.append('offset', offset);
    searchParams.append('limit', PAGE_SIZE);
    if (listId) {
      searchParams.append('list_id', listId);
    }
    if (view) {
      searchParams.append('view', view);
    }
    url.search = searchParams.toString();
    const res = await fetch(
      url,
      {
        headers: {
          ...(userToken && { Authorization: `${userToken.token_type} ${userToken.id_token}` }),
        },
      },
    );
    const json = await res.json();
    return { ...payload, namespace, searchResults: json };
  },
);

export const retrieveItemsByAuthor = createAsyncThunk(
  'curated/retrieveItemsByAuthor',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      authorId, namespace, pageNumber,
    } = payload || {};
    const userToken = state.users.authToken;
    if (namespace !== 'public' && !userToken) {
      throw new Error('Unauthenticated caller');
    }

    const offset = ((pageNumber || 1) - 1) * PAGE_SIZE;
    const url = new URL(`${getUrlPrefix(namespace)}/authors/${authorId}/items`);
    const searchParams = new URLSearchParams();
    searchParams.append('offset', offset);
    searchParams.append('limit', PAGE_SIZE);
    url.search = searchParams.toString();
    const res = await fetch(
      url,
      {
        headers: {
          ...(userToken && { Authorization: `${userToken.token_type} ${userToken.id_token}` }),
        },
      },
    );
    const itemRange = await res.json();
    return {
      ...payload, pageNumber: pageNumber || 1, itemRange,
    };
  },
);

export const retrieveAuthor = createAsyncThunk(
  'curated/retrieveAuthor',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/authors/${payload.author.id}`,
      {
        method: 'GET',
        headers: {
          ...(userToken && { Authorization: `${userToken.token_type} ${userToken.id_token}` }),
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    if (res.status === 200) {
      const result = await res.json();
      return result;
    }

    return { id: payload.author.id, deleted: true };
  },
);

export const retrieveAuthorsList = createAsyncThunk(
  'curated/retrieveAuthorsList',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      view, namespace, pageNumber,
    } = payload || {};
    const userToken = state.users.authToken;
    if (namespace !== 'public' && !userToken) {
      throw new Error('Unauthenticated caller');
    }

    const offset = ((pageNumber || 1) - 1) * PAGE_SIZE;

    const url = new URL(`${getUrlPrefix(namespace)}/authors`);
    const searchParams = new URLSearchParams();
    searchParams.append('offset', offset);
    searchParams.append('limit', PAGE_SIZE);
    if (view) {
      searchParams.append('view', view);
    }
    url.search = searchParams.toString();
    const res = await fetch(
      url,
      {
        headers: {
          ...(userToken && { Authorization: `${userToken.token_type} ${userToken.id_token}` }),
        },
      },
    );
    const authorRange = await res.json();
    return { ...payload, pageNumber: pageNumber || 1, authorRange };
  },
);

export const retrieveAuthorsSearch = createAsyncThunk(
  'curated/retrieveAuthorsSearch',
  async (payload, thunkAPI) => {
    const { filter, namespace } = payload;
    const pageNumber = payload.pageNumber || 1;
    const offset = (pageNumber - 1) * PAGE_SIZE;

    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const url = new URL(`${getUrlPrefix(namespace)}/authors`);
    const searchParams = new URLSearchParams();
    searchParams.append('filter', filter);
    searchParams.append('offset', offset);
    searchParams.append('limit', PAGE_SIZE);
    url.search = searchParams.toString();
    const res = await fetch(
      url,
      { headers: { Authorization: `${userToken.token_type} ${userToken.id_token}` } },
    );
    const json = await res.json();
    return { ...payload, namespace, searchResults: json };
  },
);

export const retrieveListInfosList = createAsyncThunk(
  'curated/retrieveListInfosList',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const { view, namespace } = payload || {};
    const userToken = state.users.authToken;
    if (namespace !== 'public' && !userToken) {
      throw new Error('Unauthenticated caller');
    }
    const pageNumber = (payload && payload.pageNumber) || 1;
    const offset = (pageNumber - 1) * PAGE_SIZE_LISTS;
    const url = new URL(`${getUrlPrefix(namespace)}/lists`);
    const searchParams = new URLSearchParams();
    searchParams.append('offset', offset);
    searchParams.append('limit', PAGE_SIZE_LISTS);
    if (view) {
      searchParams.append('view', view);
    }
    url.search = searchParams.toString();
    const res = await fetch(
      url,
      {
        headers: {
          ...(userToken && { Authorization: `${userToken.token_type} ${userToken.id_token}` }),
        },
      },
    );
    const listRange = await res.json();
    return { ...payload, pageNumber, listRange };
  },
);

export const retrieveListInfosSearch = createAsyncThunk(
  'curated/retrieveListInfosSearch',
  async (payload, thunkAPI) => {
    const { filter, namespace } = payload;
    const pageNumber = payload.pageNumber || 1;
    const offset = (pageNumber - 1) * PAGE_SIZE;

    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const url = new URL(`${getUrlPrefixListInfo(namespace)}/search`);
    const searchParams = new URLSearchParams();
    searchParams.append('filter', filter);
    searchParams.append('offset', offset);
    searchParams.append('limit', PAGE_SIZE);
    url.search = searchParams.toString();
    const res = await fetch(
      url,
      { headers: { Authorization: `${userToken.token_type} ${userToken.id_token}` } },
    );
    const json = await res.json();
    return { ...payload, searchResults: json };
  },
);

export const addItemsToList = createAsyncThunk(
  'curated/addItemsToList',
  async (payload, thunkAPI) => {
    const { items, listId } = payload;
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/lists/${listId}/items`,
      {
        method: 'POST',
        body: JSON.stringify(items),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const item = await res.json();
    return { item, listId };
  },
);

export const createListInfo = createAsyncThunk(
  'curated/createListInfo',
  async ({ listInfo, itemId }, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.users.authToken;
    if (!token) {
      return;
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/lists`,
      {
        method: 'POST',
        body: JSON.stringify(listInfo),
        headers: {
          Authorization: `${token.token_type} ${token.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const json = await res.json();
    if (itemId) {
      thunkAPI.dispatch(addItemsToList(
        { items: [{ id: itemId }], listId: json.id },
      ));
    }
    return json;
  },
);

export const retrieveListInfoAll = createAsyncThunk(
  'curated/retrieveListInfoAll',
  async (payload, thunkAPI) => {
    const { namespace } = payload || {};
    const state = thunkAPI.getState();
    const token = state.users.authToken;
    if (!token) {
      return;
    }
    const res = await fetch(
      `${getUrlPrefix(namespace)}/lists`,
      { headers: { Authorization: `${token.token_type} ${token.id_token}` } },
    );
    const json = await res.json();
    return json;
  },
);

export const updateListInfo = createAsyncThunk(
  'curated/updateListInfo',
  async (listInfo, thunkAPI) => {
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const idParam = listInfo.id ? `${listInfo.id}` : '';
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/lists/${idParam}`,
      {
        method: listInfo.id ? 'PATCH' : 'POST',
        body: JSON.stringify(listInfo),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const newItem = await res.json();
    return newItem;
  },
);

export const deleteListInfo = createAsyncThunk(
  'curated/deleteListInfo',
  async ({ listId }, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.users.authToken;
    if (!token) {
      return;
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/lists/${listId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `${token.token_type} ${token.id_token}`,
        },
      },
    );
    await res.text();
    return listId;
  },
);

export const retrieveItem = createAsyncThunk(
  'curated/retrieveItem',
  async (payload, thunkAPI) => {
    const { itemId } = payload;
    if (!itemId) return;

    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    const res = await fetch(
      `${getUrlPrefix('public')}/items/${itemId}`,
      {
        method: 'GET',
        headers: {
          ...(userToken && { Authorization: `${userToken.token_type} ${userToken.id_token}` }),
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    if (res.status === 200) {
      const result = await res.json();
      return result;
    }

    return { item: { id: itemId, deleted: true } };
  },
);

export const retrieveItems = createAsyncThunk(
  'curated/retrieveItems',
  async (payload, thunkAPI) => {
    const { namespace } = payload;
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (namespace !== 'public' && !userToken) {
      throw new Error('Unauthenticated caller');
    }
    const { filter } = payload || {};
    if (filter) {
      thunkAPI.dispatch(retrieveItemsSearch(payload));
    } else {
      thunkAPI.dispatch(retrieveItemsList(payload));
    }
  },
);

export const retrieveListInfos = createAsyncThunk(
  'curated/retrieveListInfos',
  async (payload, thunkAPI) => {
    const filter = payload && payload.filter;
    if (filter) {
      thunkAPI.dispatch(retrieveListInfosSearch(payload));
    } else {
      thunkAPI.dispatch(retrieveListInfosList(payload));
    }
  },
);

export const setAuthorsContext = createAsyncThunk(
  'curated/setAuthorsContext',
  async (payload, thunkAPI) => {
    const { filter } = payload || {};
    if (filter) {
      thunkAPI.dispatch(retrieveAuthorsSearch(payload));
    } else {
      thunkAPI.dispatch(retrieveAuthorsList(payload));
    }
  },
);

export const setContext = createAsyncThunk(
  'curated/setContext',
  async (payload, thunkAPI) => {
    const { type } = payload || {};
    switch (type) {
      case 'authors':
        thunkAPI.dispatch(setAuthorsContext(payload));
        break;
      case 'author':
        thunkAPI.dispatch(retrieveAuthor(payload));
        break;
      case 'authorItems':
        thunkAPI.dispatch(retrieveItemsByAuthor(payload));
        break;
      case 'lists':
        thunkAPI.dispatch(retrieveListInfos(payload));
        break;
      case 'list':
        thunkAPI.dispatch(retrieveListInfo(payload));
        break;
      case 'items':
        thunkAPI.dispatch(retrieveItems(payload));
        break;
      case 'item':
        thunkAPI.dispatch(retrieveItem(payload));
        break;
      default:
        break;
    }
  },
);

export const updateContext = createAsyncThunk(
  'curated/updateContext',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const { context } = state.curated;
    const baseContext = preserveContextBase(context);
    thunkAPI.dispatch(setContext({ ...baseContext, ...payload }));
  },
);

export const movePage = createAsyncThunk(
  'curated/movePage',
  async ({ addPages }, thunkAPI) => {
    const state = thunkAPI.getState();
    const { context } = state.curated;
    const baseContext = preserveContextBase(context);
    const newContext = {
      ...baseContext,
      pageNumber: (baseContext.pageNumber || 1) + addPages,
    };
    thunkAPI.dispatch(setContext(newContext));
  },
);

export const createItem = createAsyncThunk(
  'curated/createItem',
  async (payload, thunkAPI) => {
    const { listId, item, onItemCreated } = payload;
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const listUrl = listId ? `/lists/${listId}` : '';
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}${listUrl}/items`,
      {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const newItem = await res.json();
    if (onItemCreated) {
      onItemCreated(newItem);
    }
    return newItem;
  },
);

export const removeItemFromList = createAsyncThunk(
  'curated/removeItemFromList',
  async (payload, thunkAPI) => {
    const { itemId, listId } = payload;
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/lists/${listId}/items/${itemId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    await res.text();
    // TODO hack, update local state instead
    thunkAPI.dispatch(movePage({ addPages: 0 }));
    return { itemId, listId };
  },
);

export const updateItem = createAsyncThunk(
  'curated/updateItem',
  async (payload, thunkAPI) => {
    const { item, refresh } = payload;
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      return;
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/items/${item.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(item),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const result = await res.json();
    if (refresh) {
      thunkAPI.dispatch(movePage({ addPages: 0 }));
    }
    const { item: updatedItem, authors } = result;
    return { refresh, item: updatedItem, authors };
  },
);

export const updateAuthor = createAsyncThunk(
  'curated/updateAuthor',
  async (payload, thunkAPI) => {
    const { author, namespace } = payload;
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      return;
    }
    const res = await fetch(
      `${getUrlPrefix(namespace)}/authors/${author.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(author),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const newAuthor = await res.json();
    return newAuthor;
  },
);

export const updateItemBatch = createAsyncThunk(
  'curated/updateItemBatch',
  async (payload, thunkAPI) => {
    const { ids, patch } = payload;
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      return;
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/items`,
      {
        method: 'PATCH',
        body: JSON.stringify({ ids, patch }),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    await res.text();
    thunkAPI.dispatch(movePage({ addPages: 0 }));
    return ids;
  },
);

export const mergeItems = createAsyncThunk(
  'curated/mergeItems',
  async (payload, thunkAPI) => {
    const { targetItemId, sourceItemIds } = payload;
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      return;
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/items/${targetItemId}/merge`,
      {
        method: 'PATCH',
        body: JSON.stringify({ itemIds: sourceItemIds }),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const newItem = await res.json();
    thunkAPI.dispatch(movePage({ addPages: 0 }));
    return newItem;
  },
);

export const cloneItem = createAsyncThunk(
  'curated/cloneItem',
  async (payload, thunkAPI) => {
    const itemId = payload;
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      return;
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/items/${itemId}/clone`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const newItem = await res.json();
    thunkAPI.dispatch(movePage({ addPages: 0 }));
    return newItem;
  },
);

export const deleteItem = createAsyncThunk(
  'curated/deleteItem',
  async (payload, thunkAPI) => {
    const { item, namespace, refresh } = payload;
    const state = thunkAPI.getState();
    const userToken = state.users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const itemId = item.id;
    const res = await fetch(
      `${getUrlPrefix(namespace)}/items/${itemId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    await res.text();
    if (refresh) {
      thunkAPI.dispatch(movePage({ addPages: 0 }));
    }
    return item;
  },
);

export const retrieveTags = createAsyncThunk(
  'curated/retrieveTags',
  async (_, thunkAPI) => {
    const userToken = thunkAPI.getState().users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/tags`,
      {
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
        },
      },
    );
    const json = await res.json();
    return json;
  },
);

export const approveItem = createAsyncThunk(
  'curated/approveItem',
  async (item, thunkAPI) => {
    const userToken = thunkAPI.getState().users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const approvedItem = { visibility: 'public' };
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/pending/items/${item.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(approvedItem),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const newItem = await res.json();
    thunkAPI.dispatch(movePage({ addPages: 0 }));
    return { item: newItem, pendingItemId: item.id };
  },
);

export const approveAuthor = createAsyncThunk(
  'curated/approveAuthor',
  async (author, thunkAPI) => {
    const userToken = thunkAPI.getState().users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const approvedAuthor = { visibility: 'public' };
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/pending/authors/${author.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(approvedAuthor),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const newAuthor = await res.json();
    thunkAPI.dispatch(movePage({ addPages: 0 }));
    return { author: newAuthor, pendingAuthorId: author.id };
  },
);

export const approveList = createAsyncThunk(
  'curated/approveList',
  async (list, thunkAPI) => {
    const userToken = thunkAPI.getState().users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const approvedList = { visibility: 'public' };
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/pending/lists/${list.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(approvedList),
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const newList = await res.json();
    thunkAPI.dispatch(movePage({ addPages: 0 }));
    return { list: newList, pendingListId: list.id };
  },
);

export const rejectItem = createAsyncThunk(
  'curated/rejectItem',
  async (item, thunkAPI) => {
    const userToken = thunkAPI.getState().users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/pending/items/${item.id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    await res.text();
    thunkAPI.dispatch(movePage({ addPages: 0 }));
    return { pendingItemId: item.id };
  },
);

export const rejectAuthor = createAsyncThunk(
  'curated/rejectAuthor',
  async (author, thunkAPI) => {
    const userToken = thunkAPI.getState().users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/pending/authors/${author.id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    await res.text();
    thunkAPI.dispatch(movePage({ addPages: 0 }));
    return { pendingAuthorId: author.id };
  },
);

export const rejectList = createAsyncThunk(
  'curated/rejectList',
  async (list, thunkAPI) => {
    const userToken = thunkAPI.getState().users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/pending/lists/${list.id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    await res.text();
    thunkAPI.dispatch(movePage({ addPages: 0 }));
    return { pendingListId: list.id };
  },
);

export const shareItem = createAsyncThunk(
  'curatedItems/shareItem',
  async (payload, thunkAPI) => {
    const { item } = payload;
    const userToken = thunkAPI.getState().users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const itemId = item.id;
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/public/items/${itemId}`,
      {
        method: 'POST',
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const newItem = await res.json();
    return { item: newItem };
  },
);

export const shareList = createAsyncThunk(
  'curatedItems/shareList',
  async (payload, thunkAPI) => {
    const { list } = payload;
    const userToken = thunkAPI.getState().users.authToken;
    if (!userToken) {
      throw new Error('Unauthenticated caller');
    }
    const listId = list.id;
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/public/lists/${listId}`,
      {
        method: 'POST',
        headers: {
          Authorization: `${userToken.token_type} ${userToken.id_token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    );
    const newList = await res.json();
    return { list: newList };
  },
);

// #region Create slice

const curatedSlice = createSlice({
  name: 'private',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(movePage.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(movePage.fulfilled, () => {
      })
      .addCase(movePage.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(createListInfo.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(createListInfo.fulfilled, (state, action) => {
        listsAdapter.upsertOne(state.lists, action.payload);
        state.contextStatus = 'succeeded';
      })
      .addCase(createListInfo.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveListInfo.pending, (state) => {
        state.listInfoStatus = 'loading';
      })
      .addCase(retrieveListInfo.fulfilled, (state, action) => {
        state.context = {
          ...state.context,
          listInfo: action.payload.listInfo,
        };
        state.listInfoStatus = 'succeeded';
        if (action.payload.refresh) {
          state.contextStatus = 'succeeded';
        }
      })
      .addCase(retrieveListInfo.rejected, (state, action) => {
        state.error = action.error.message;
        state.listInfoStatus = 'failed';
      })
      .addCase(retrieveListInfoAll.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveListInfoAll.fulfilled, (state, action) => {
        listsAdapter.upsertMany(state.lists, action.payload.lists);
        state.contextStatus = 'succeeded';
      })
      .addCase(retrieveListInfoAll.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(updateListInfo.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(updateListInfo.fulfilled, (state, action) => {
        listsAdapter.upsertOne(state.lists, action.payload);
        state.contextStatus = 'succeeded';
      })
      .addCase(updateListInfo.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(deleteListInfo.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(deleteListInfo.fulfilled, (state, action) => {
        listsAdapter.removeOne(state.lists, action.payload);
        state.contextStatus = 'succeeded';
      })
      .addCase(deleteListInfo.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(createItem.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(createItem.fulfilled, (state, action) => {
        const item = action.payload;
        let { items } = state.context;
        if (items) {
          const ind = items.findIndex((i) => i.id === item.id);
          items = [...items];
          items[ind] = item;
        }
        state.context = {
          ...state.context,
          items,
          item,
        };

        if (item.privateData && item.privateData.tags) {
          const tags = item.privateData.tags.map((t) => t.name);
          tagsPrivateAdapter.upsertMany(state.tagsPrivate, tags);
        }
        if (item.publicData && item.publicData.tags) {
          const tags = item.publicData.tags.map((t) => t.name);
          tagsPublicAdapter.upsertMany(state.tagsPublic, tags);
        }

        state.contextStatus = 'succeeded';
      })
      .addCase(createItem.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveItem.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveItem.fulfilled, (state, action) => {
        const { item, authors } = action.payload;
        state.context = {
          type: 'item',
        };
        if (item) {
          state.context.item = item;
          state.context.itemId = item.id;
        }
        if (authors && authors.length) {
          authorsAdapter.upsertMany(state.authors, authors);
        }
        state.contextStatus = 'succeeded';
      })
      .addCase(retrieveItem.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveAuthor.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveAuthor.fulfilled, (state, action) => {
        const { payload: author } = action;
        state.context = {
          ...state.context,
          author,
        };
        authorsAdapter.upsertOne(state.authors, author);
        state.contextStatus = 'succeeded';
      })
      .addCase(retrieveAuthor.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveItems.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveItems.fulfilled, () => {
      })
      .addCase(retrieveItems.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveListInfos.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveListInfos.fulfilled, () => {
      })
      .addCase(retrieveListInfos.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveItemsList.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveItemsList.fulfilled, (state, action) => {
        const {
          listId, view, pageNumber, itemRange, namespace,
        } = action.payload;
        const {
          authors, itemsCount, items, stats,
        } = itemRange;

        state.context = {
          type: 'items',
          ...(namespace && { namespace }),
          ...(listId && { listId }),
          ...(view && { view }),
          pageNumber,
          pagesCount: Math.ceil(itemsCount / PAGE_SIZE),
          stats,
          itemsCount,
          items,
        };
        if (authors && authors.length) {
          authorsAdapter.upsertMany(state.authors, authors);
        }
        state.contextStatus = 'succeeded';
      })
      .addCase(retrieveItemsList.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveItemsByAuthor.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveItemsByAuthor.fulfilled, (state, action) => {
        const {
          authorId, pageNumber, itemRange, namespace,
        } = action.payload;
        const {
          authors, itemsCount, items, stats,
        } = itemRange;

        state.context = {
          type: 'authorItems',
          authorId,
          ...(namespace && { namespace }),
          pageNumber,
          pagesCount: Math.ceil(itemsCount / PAGE_SIZE),
          ...(stats && { stats }),
          itemsCount,
          items,
        };
        if (authors && authors.length) {
          authorsAdapter.upsertMany(state.authors, authors);
        }
        state.contextStatus = 'succeeded';
      })
      .addCase(retrieveItemsByAuthor.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveAuthorsList.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveAuthorsList.fulfilled, (state, action) => {
        const {
          view, pageNumber, authorRange, namespace,
        } = action.payload;
        const { authorCount, authors, stats } = authorRange;

        state.context = {
          type: 'authors',
          ...(namespace && { namespace }),
          ...(view && { view }),
          pageNumber,
          pagesCount: Math.ceil(authorCount / PAGE_SIZE),
          stats,
          authorCount,
          authors,
        };
        state.contextStatus = 'succeeded';
      })
      .addCase(retrieveAuthorsList.rejected, (state, action) => {
        state.contextError = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveAuthorsSearch.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveAuthorsSearch.fulfilled, (state, action) => {
        const {
          namespace, pageNumber, filter, searchResults,
        } = action.payload;
        const { count, authors } = searchResults;

        state.context = {
          type: 'authors',
          ...(namespace && { namespace }),
          pageNumber,
          pagesCount: Math.ceil(count / PAGE_SIZE),
          count,
          authors,
          filter,
        };
        state.contextStatus = 'succeeded';
      })
      .addCase(retrieveAuthorsSearch.rejected, (state, action) => {
        state.contextError = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveListInfosList.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveListInfosList.fulfilled, (state, action) => {
        const {
          view, pageNumber, listRange, namespace,
        } = action.payload;
        const { listCount, lists } = listRange;

        state.context = {
          type: 'lists',
          ...(namespace && { namespace }),
          ...(view && { view }),
          pageNumber,
          pagesCount: Math.ceil(listCount / PAGE_SIZE_LISTS),
          listCount,
          lists,
        };
        state.contextStatus = 'succeeded';
      })
      .addCase(retrieveListInfosList.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveItemsSearch.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveItemsSearch.fulfilled, (state, action) => {
        const {
          namespace, pageNumber, filter, searchResults,
        } = action.payload;
        const { authors, itemsCount, items } = searchResults;

        state.context = {
          type: 'items',
          ...(namespace && { namespace }),
          pageNumber,
          pagesCount: Math.ceil(itemsCount / PAGE_SIZE),
          itemsCount,
          items,
          filter,
        };
        if (authors) {
          authorsAdapter.upsertMany(state.authors, authors);
        }
        state.contextStatus = 'succeeded';
      })
      .addCase(retrieveItemsSearch.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(retrieveListInfosSearch.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(retrieveListInfosSearch.fulfilled, (state, action) => {
        const { pageNumber, filter, searchResults } = action.payload;
        const { listsCount, lists } = searchResults;

        state.context = {
          type: 'lists',
          ...state.context,
          pageNumber,
          pagesCount: Math.ceil(listsCount / PAGE_SIZE),
          listsCount,
          lists,
          filter,
        };
        state.contextStatus = 'succeeded';
      })
      .addCase(retrieveListInfosSearch.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(updateAuthor.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(updateAuthor.fulfilled, (state, action) => {
        const author = action.payload;
        state.context = {
          type: 'author',
          ...state.context,
          author,
        };
        authorsAdapter.upsertOne(state.authors, author);
        state.contextStatus = 'succeeded';
      })
      .addCase(updateAuthor.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(updateItem.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        const { item, authors, refresh } = action.payload;
        if (!refresh) {
          let { items } = state.context;
          if (items) {
            const ind = items.findIndex((i) => i.id === item.id);
            items = [...items];
            items[ind] = item;
          }
          state.context = {
            type: 'items',
            ...state.context,
            items,
            item,
          };
          authorsAdapter.upsertMany(state.authors, authors);
          state.contextStatus = 'succeeded';
        }
      })
      .addCase(updateItem.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(updateItemBatch.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(updateItemBatch.fulfilled, (state) => {
        state.contextStatus = 'succeeded';
      })
      .addCase(updateItemBatch.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(mergeItems.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(mergeItems.fulfilled, (state, action) => {
        itemsAdapter.upsertOne(state.items, action.payload);
      })
      .addCase(mergeItems.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(cloneItem.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(cloneItem.fulfilled, (state) => {
        state.contextStatus = 'succeeded';
      })
      .addCase(cloneItem.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(addItemsToList.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(addItemsToList.fulfilled, (state) => {
        state.contextStatus = 'succeeded';
        // TODO update local state
      })
      .addCase(addItemsToList.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(removeItemFromList.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(removeItemFromList.fulfilled, (state) => {
        state.contextStatus = 'succeeded';
      })
      .addCase(removeItemFromList.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(deleteItem.pending, () => {
      })
      .addCase(deleteItem.fulfilled, (state, action) => {
        itemsAdapter.removeOne(state.items, action.payload);
      })
      .addCase(deleteItem.rejected, () => {
      })
      .addCase(retrieveTags.pending, (state) => {
        state.tagStatus = 'loading';
      })
      .addCase(retrieveTags.fulfilled, (state, action) => {
        const tags = action.payload;
        if (tags.privateData) {
          tagsPrivateAdapter.upsertMany(state.tagsPrivate, tags.privateData);
        }
        if (tags.publicData) {
          tagsPublicAdapter.upsertMany(state.tagsPublic, tags.publicData);
        }
        state.tagStatus = 'succeeded';
      })
      .addCase(retrieveTags.rejected, (state, action) => {
        state.error = action.error.message;
        state.tagStatus = 'failed';
      })
      .addCase(approveItem.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(approveItem.fulfilled, (state, action) => {
        itemsAdapter.upsertOne(state.items, action.payload.item);
        itemsPendingAdapter.removeOne(state.itemsPending, action.payload.pendingItemId);
        state.contextStatus = 'succeeded';
      })
      .addCase(approveItem.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(approveAuthor.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(approveAuthor.fulfilled, (state, action) => {
        authorsAdapter.upsertOne(state.authors, action.payload.author);
        itemsPendingAdapter.removeOne(state.itemsPending, action.payload.pendingItemId);
        state.contextStatus = 'succeeded';
      })
      .addCase(approveAuthor.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(approveList.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(approveList.fulfilled, (state, action) => {
        listsAdapter.upsertOne(state.lists, action.payload.list);
        listsPendingAdapter.removeOne(state.listsPending, action.payload.pendingListId);
        state.contextStatus = 'succeeded';
      })
      .addCase(approveList.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(rejectItem.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(rejectItem.fulfilled, (state, action) => {
        itemsPendingAdapter.removeOne(state.itemsPending, action.payload.pendingItemId);
        state.contextStatus = 'succeeded';
      })
      .addCase(rejectItem.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(rejectAuthor.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(rejectAuthor.fulfilled, (state, action) => {
        authorsPendingAdapter.removeOne(state.authorsPending, action.payload.pendingAuthorId);
        state.contextStatus = 'succeeded';
      })
      .addCase(rejectAuthor.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(rejectList.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(rejectList.fulfilled, (state, action) => {
        listsPendingAdapter.removeOne(state.listsPending, action.payload.pendingListId);
        state.contextStatus = 'succeeded';
      })
      .addCase(rejectList.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(shareItem.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(shareItem.fulfilled, (state, action) => {
        itemsPendingAdapter.upsertOne(state.itemsPending, action.payload.item);
        state.contextStatus = 'succeeded';
      })
      .addCase(shareList.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(shareList.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(shareList.fulfilled, (state, action) => {
        const newList = action.payload.list;
        listsAdapter.upsertOne(state.lists, newList);
        listsPendingAdapter.upsertOne(state.listsPending, newList);
        state.contextStatus = 'succeeded';
      })
      .addCase(shareItem.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(setContext.pending, (state) => {
        state.context = null;
        state.contextStatus = 'loading';
      })
      .addCase(setContext.fulfilled, () => {
      })
      .addCase(setContext.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(setAuthorsContext.pending, (state) => {
        state.context = null;
        state.contextStatus = 'loading';
      })
      .addCase(setAuthorsContext.fulfilled, () => {
      })
      .addCase(setAuthorsContext.rejected, (state, action) => {
        state.contextError = action.error.message;
        state.contextStatus = 'failed';
      })
      .addCase(updateContext.pending, (state) => {
        state.contextStatus = 'loading';
      })
      .addCase(updateContext.fulfilled, () => {
      })
      .addCase(updateContext.rejected, (state, action) => {
        state.error = action.error.message;
        state.contextStatus = 'failed';
      });
  },
});

export default curatedSlice.reducer;

export const {
  selectAll: selectAllUserItems,
  selectById: selectItemById,
  selectIds: selectItemIds,
} = itemsAdapter.getSelectors((state) => state.curated.items);

export const {
  selectAll: selectAllAuthors,
  selectById: selectAuthorById,
} = authorsAdapter.getSelectors((state) => state.curated.authors);

export const {
  selectAll: selectAllLists,
  selectById: selectListById,
  selectIds: selectListIds,
} = listsAdapter.getSelectors((state) => state.curated.lists);

export const {
  selectAll: selectAllTagsPublic,
} = tagsPrivateAdapter.getSelectors((state) => state.curated.tagsPublic);

export const {
  selectAll: selectAllTagsPrivate,
} = tagsPrivateAdapter.getSelectors((state) => state.curated.tagsPrivate);

export const selectAllUserLists = createSelector(
  [selectAllLists],
  (lists) => {
    lists.filter((list) => !list.autoGenerated);
    lists.sort((l1, l2) => {
      if (!l1 || !l2 || !l1.privateData || !l2.privateData) return 0;
      return (l2.privateData.timeUpdated - l1.privateData.timeUpdated);
    });
    return lists;
  },
);

export const selectAuthors = createSelector(
  [
    selectAllAuthors,
    (state, ids) => ids,
  ],
  (authors, ids) => ids && authors.filter((author) => ids.includes(author.id)),
);

export const selectContext = (state) => state.curated.context;

export const selectContextPageNumber = (state) => state.curated.context
  && state.curated.context.pageNumber;

export const selectContextFilter = (state) => state.curated.context
  && state.curated.context.filter;

export const selectCurrentListInfo = (state) => {
  const { context } = state.curated;
  if (!context) {
    return {};
  }

  const { listId, namespace } = context;
  if (!listId) {
    return null;
  }

  const listInfo = selectListById(state, context.listId);
  if (!listInfo) {
    return listInfo;
  }

  if (namespace === 'public') {
    return listInfo.publicData;
  }

  return listInfo.privateData;
};
