/* eslint-disable no-console */
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Box } from '@mui/system';

const Expand = styled(ExpandMoreIcon)(({ expanded, theme }) => ({
  transform: (expanded ? 'rotate(180deg)' : 'rotate(0deg)'),
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function TestView() {
  const theme = createTheme({});
  const [expanded, setExpanded] = useState(false);

  const handleClick = React.useCallback(() => {
    console.log(expanded);
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Expand expanded={expanded ? 1 : 0} onClick={handleClick} />
      </Box>
    </ThemeProvider>
  );
}

export default TestView;
