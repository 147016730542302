import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import { setSnackbar } from '../redux/features/snackbarSlice';

function ListSnackbar() {
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state) => state.snackbar.snackbarType);
  const snackbarMessage = useSelector((state) => state.snackbar.snackbarMessage);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbar({
      snackbarOpen: false,
      snackbarType,
      snackbarMessage,
    }));
  };

  return (
    <Snackbar
      autoHideDuration={3000}
      open={snackbarOpen}
      onClose={handleClose}
    >
      <Alert
        color={snackbarType}
        elevation={6}
        variant="filled"
        onClose={handleClose}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}

export default ListSnackbar;
