import { Box, styled } from '@mui/system';
import {
  ButtonBase, Card, CardContent, CardHeader, CardMedia, Chip, IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import LikeIcon from '@mui/icons-material/ThumbUp';
import ShareIcon from '@mui/icons-material/Share';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TimerIcon from '@mui/icons-material/Timer';
import TrendingUpSharpIcon from '@mui/icons-material/TrendingUpSharp';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { mergePublicAndPrivateItems } from '../common/ItemTools';
import listLogo from '../resources/list.png';

const StyledLikeIcon = styled(LikeIcon)(({ checked }) => checked && { color: 'red' });

const CardRoot = styled(Card)({
  display: 'flex',
  margin: '10px',
});

const CardMediaCover = styled(CardMedia)({
  minWidth: '120px',
  backgroundSize: 'contain',
  margin: '10px',
});

const CardContentInfo = styled(CardContent)({
  flex: '1 0 auto',
});

const Details = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
});

const ChipBox = styled(Box)({
  display: 'flex',
  marginTop: '15px',
});

const ChipCounter = styled(Chip)({
  marginRight: '5px',
});

export default function ListInfoCard(props) {
  const {
    listInfo, onListOpen, onListShare, onListApprove, onListDelete,
  } = props;
  const navigate = useNavigate();
  const highResolution = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const listInfoCombined = mergePublicAndPrivateItems(listInfo);
  let timeNeeded;
  if (listInfoCombined.timeNeeded) {
    timeNeeded = moment.duration(listInfoCombined.timeNeeded, 'minutes').humanize();
  }
  let timeSpent;
  if (listInfoCombined.listInfoCombined) {
    timeSpent = moment.duration(listInfoCombined.timeSpent, 'minutes').humanize();
  }
  let totalItems;
  if (listInfoCombined.totalItems) totalItems = listInfoCombined.totalItems;

  const handleListOpen = () => {
    if (onListOpen) {
      onListOpen(listInfo);
    }
  };

  return (
    <CardRoot>
      <CardMediaCover
        image={listInfoCombined.coverImageUrl || listLogo}
        title={listInfoCombined.title}
        onClick={handleListOpen}
      />
      <Details>
        <CardHeader
          action={highResolution && (
            <div>
              { onListShare && (
              <IconButton size="small" onClick={() => onListShare(listInfo)}>
                <ShareIcon />
              </IconButton>
              )}
              { onListApprove && (
              <IconButton size="small" onClick={() => onListApprove(listInfo)}>
                <DoneIcon />
              </IconButton>
              )}
              { onListDelete && (
              <IconButton size="small" onClick={() => onListDelete(listInfo)}>
                <CancelIcon />
              </IconButton>
              )}
              <IconButton
                onClick={() => navigate(`/edit/list/${listInfoCombined.id}`)}
              >
                <EditIcon />
              </IconButton>
              <IconButton>
                <StyledLikeIcon checked={listInfo.isFavorite ? 1 : 0} />
              </IconButton>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </div>
          )}
          subheader={listInfoCombined.authors && listInfoCombined.authors[0].name}
          title={listInfoCombined.title}
        />
        <ButtonBase onClick={() => navigate(`/learn/${listInfoCombined.id}`)}>
          <CardContentInfo>
            <Typography align="left">
              {listInfoCombined.description && listInfoCombined.description }
            </Typography>
            { highResolution && (
              <ChipBox>
                {!totalItems && (
                  <ChipCounter
                    color="error"
                    icon={<FormatListBulletedSharpIcon />}
                    label="Empty list"
                  />
                )}
                {totalItems && (
                  <ChipCounter
                    icon={<FormatListBulletedSharpIcon />}
                    label={`${listInfoCombined.totalItems.toLocaleString('en-US')} items`}
                  />
                )}
                {timeNeeded && (
                  <ChipCounter
                    icon={<TimerIcon />}
                    label={`${timeNeeded} needed`}
                  />
                )}
                {timeSpent && (
                  <ChipCounter
                    icon={<TrendingUpSharpIcon />}
                    label={`${timeSpent} spent`}
                  />
                )}
              </ChipBox>
            )}
          </CardContentInfo>
        </ButtonBase>
      </Details>
    </CardRoot>
  );
}

ListInfoCard.propTypes = {
  listInfo: PropTypes.object.isRequired,
};
