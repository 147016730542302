import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { mergePublicAndPrivateItems } from '../common/ItemTools';
import ItemCard from './ItemCard';
import PageStepper from './PageStepper';

export default function ItemCardGrid(props) {
  const {
    currentPage,
    itemActions,
    listActions,
  } = props;

  const { handleNextPage, handlePreviousPage } = listActions;

  const {
    pagesCount, pageNumber, items, namespace,
  } = currentPage || {};

  return (
    <>
      {items && items.length > 0
        && (
        <Grid spacing={2} container>
          {
            items.map((item) => mergePublicAndPrivateItems(item)).map(
              (item) => (
                <Grid key={item.id} lg={3} sm={6} xs={12} item>
                  <ItemCard
                    isPreview={namespace === 'public'}
                    item={item}
                    key={item.id}
                    {...itemActions}
                  />
                </Grid>
              ),
            )
          }
        </Grid>
        )}
      {pagesCount > 1 && (
        <PageStepper {...{
          pagesCount, pageNumber, handleNextPage, handlePreviousPage,
        }}
        />
      )}
    </>
  );
}

ItemCardGrid.propTypes = {
  currentPage: PropTypes.object.isRequired,
  itemActions: PropTypes.object.isRequired,
  listActions: PropTypes.object.isRequired,
};
