import {
  AppBar, Avatar, Button, ButtonBase, Chip, Divider, Grid, Hidden, IconButton,
  Menu, MenuItem, Tab, Tabs, TextField, Typography,
  useMediaQuery,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useState } from 'react';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  retrieveTags, updateContext, selectContext, setContext, selectAuthorById,
} from '../redux/features/curatedSlice';
import { fetchUserInfo, logOut, updateAuthToken } from '../redux/features/usersSlice';
import ViewDrawer from './ViewDrawer';
import ItemEditDialog from './ItemEditDialog';
import { setSnackbar } from '../redux/features/snackbarSlice';
import { getAuthorName, getListTitle, isPublicNamespace } from '../common/ItemTools';

const clientId = '323198785229-4v5vetueh64qb7bkas8sr7gp2mepvi5k.apps.googleusercontent.com';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'white',
}));

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  margin: '3px',
  color: theme.palette.text.secondary,
}));

const AvatarBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [theme.breakpoints.down('xs')]: {
    flexGrow: '1',
  },
}));

const StyledTabs = styled(Tabs)({
  flexGrow: 1,
});

const StyledTab = styled(Tab)({
  fontSize: '1.5rem',
  fontWeight: 700,
  color: 'text.primary',
});

const LoginButton = styled(Button)({
  fontSize: '1.25rem',
  margin: '5px 5px 5px 5px',
});

const HomeLink = styled(Link)({
  display: 'flex',
  cursor: 'pointer',
  textDecoration: 'none',
});

const Title = styled(Typography)({
  fontFamily: '"Roboto Condensed", Roboto, sans-serif',
  fontWeight: 700,
  textTransform: 'uppercase',
  marginTop: '10px',
});

const GridFlex = styled(Grid)({
  display: 'flex',
});

const GridTitleBox = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const SearchContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  padding: '0 15px 0 15px',
});

const SearchInput = styled(TextField)({
  width: '200px',
  margin: '5px',
  flexGrow: 1,
});

const MenuIconButton = styled(IconButton)({
  minWidth: '64px',
});

const ListTitle = styled(Typography)({
  fontFamily: '"Roboto Condensed", Roboto, sans-serif',
  fontWeight: 700,
  color: 'text.primary',
});

const ChipTimeSpent = styled(Chip)({
  marginLeft: '5px',
  color: 'green',
  fontWeight: 'bold',
});

const ChipTimeNeeded = styled(Chip)(({ theme }) => ({
  margin: '10px',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

const ChipItemCount = styled(Chip)(({ theme }) => ({
  marginLeft: '5px',
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
}));

const StyledMenu = styled(Menu)({
  paper: {
    border: '1px solid #d3d4d5',
  },
});

const StyledMenuItem = styled(MenuItem)({
  '&:focus': {
    backgroundColor: 'secondary',
  },
});

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const highResolution = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const userProfile = useSelector((state) => state.users.authProfile);
  const userInfo = useSelector((state) => state.users);
  const context = useSelector(selectContext);
  const listInfoStatus = useSelector((state) => state.curated.listInfoStatus);
  const listInfo = context && context.listInfo;

  const {
    filter, itemsCount, namespace, view, authorId, type,
  } = context || {};
  const authorInfo = useSelector((state) => selectAuthorById(state, authorId));
  const authorName = getAuthorName(authorInfo);

  const { timeNeeded, timeSpent } = (listInfo && (listInfo.privateData || listInfo.publicData))
  || (context && context.stats) || {};
  const itemsCountFormatted = (itemsCount && itemsCount.toLocaleString('en-US'));
  const timeSpentMinutes = timeSpent && timeSpent.toLocaleString('en-US');
  const timeNeededHours = timeNeeded
    && Math.round(timeNeeded / 60).toLocaleString('en-US');
  const timeNeededMinutes = timeNeeded && timeNeeded.toLocaleString('en-US');
  let percent = timeSpent && timeNeeded && (100 * (timeSpent / timeNeeded));
  percent = percent && (percent === 100 ? percent : percent.toFixed(1));

  const { givenName, imageUrl } = userProfile || {};

  let listTitle = '>';
  const tt = getListTitle(listInfo);
  if (tt) {
    const title = tt.title || '';
    const { type: listType, id: listId } = tt;
    if (listType === 'tag') listTitle = `#${listId}`;
    else listTitle = `> ${title}`;
  }

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const menuOpen = Boolean(menuAnchor);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerClose = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClick = (pageURL) => {
    setMenuAnchor(null);
    navigate(pageURL);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const onLoginSuccess = (res) => {
    dispatch(updateAuthToken({
      authProfile: res.profileObj,
      authToken: res.tokenObj,
    }));
    dispatch(fetchUserInfo());
    dispatch(retrieveTags());
  };

  const onFinish = () => {};

  const onLoginFailure = () => {
    dispatch(setSnackbar({
      snackbarOpen: true,
      snackbarType: 'error',
      snackbarMessage: 'Failed to log in.',
    }));
  };
  const onLogoutSuccess = () => {
    setDrawerOpen(false);
    dispatch(logOut());
    setMenuAnchor(null);
  };
  const onLogoutFailure = () => {
    setDrawerOpen(false);
    dispatch(setSnackbar({
      snackbarOpen: true,
      snackbarType: 'error',
      snackbarMessage: 'Failed to log out.',
    }));
  };

  const [tabValue, setTabValue] = React.useState(1);
  const [itemEditDialog, setItemEditDialog] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleItemEditDialogClose = () => {
    setItemEditDialog(false);
  };

  const isPublic = isPublicNamespace(namespace);
  let newTabValue;
  if (isPublic) {
    newTabValue = 1;
  } else if (!namespace) {
    if (view === 'share') {
      newTabValue = 2;
    } else {
      newTabValue = 0;
    }
  }
  if (tabValue !== newTabValue) {
    setTabValue(newTabValue);
  }

  const [searchBoxEnabled, setSearchEnabled] = useState(!!filter);
  const searchEnabled = searchBoxEnabled || filter;

  const [viewType, setViewType] = useState();
  if (type !== viewType) {
    setSearchEnabled(false);
    setViewType(type);
  }

  const onCancelSearch = () => {
    dispatch(updateContext({ filter: null, pageNumber: 1 }));
    setSearchEnabled(false);
  };

  const onListDelete = (newListInfo) => {
    dispatch(deleteListInfo({ listId: newListInfo.id }));
    navigate('/learn');
  };

  const onListRefresh = () => {
    dispatch(setContext({ context }));
  };

  const { signIn } = useGoogleLogin({
    clientId,
    onSuccess: onLoginSuccess,
    onFailure: onLoginFailure,
    onAutoLoadFinished: onFinish,
    isSignedIn: true,
    accessType: 'offline',
  });

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure: onLogoutFailure,
  });

  const handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      dispatch(updateContext({ filter: e.target.value, pageNumber: 1 }));
    }
  };

  return (
    <>
      <StyledAppBar elevation={0} position="fixed" variant="outlined">
        <Grid container>
          <GridFlex key="menu-logo" sm={2} xs={6} item>
            <MenuIconButton onClick={handleDrawerClose}>
              <MenuIcon />
            </MenuIconButton>
            {highResolution && (
              <HomeLink to="/explore">
                <Title
                  color="primary"
                  variant="h4"
                >
                  Curated
                </Title>
                <Title
                  color="secondary"
                  variant="h4"
                >
                  List
                </Title>
              </HomeLink>
            )}
          </GridFlex>
          {highResolution && !searchEnabled && (
            <GridTitleBox key="title" sm={8} item>
              { !isPublic && listInfo && !listInfo.autoGenerated && (
                <StyledButton
                  onClick={() => navigate(`/edit/list/${listInfo.id}`)}
                >
                  <EditIcon />
                </StyledButton>
              )}
              { !isPublic && authorId && (
                <StyledButton
                  onClick={() => navigate(`/edit/author/${authorId}`)}
                >
                  <EditIcon />
                </StyledButton>
              )}
              {authorName && (
              <ListTitle variant="h4">
                  {authorName}
              </ListTitle>
              )}
              {listInfo && (
              <ListTitle variant="h4">
                  {listTitle}
              </ListTitle>
              )}
              {listInfoStatus !== 'loading' && !authorId && !listInfo && (
                <Hidden smDown>
                  <StyledTabs
                    textColor="inherit"
                    value={tabValue}
                    centered
                    onChange={handleTabChange}
                  >
                    <StyledTab component={Link} label="Learn" to="/learn" />
                    <StyledTab component={Link} label="Explore" to="/explore" />
                    <StyledTab component={Link} label="Share" to="/share" />
                  </StyledTabs>
                </Hidden>
              )}
            </GridTitleBox>
          )}
          {highResolution && searchEnabled && (
          <Grid key="banner" sm={8} item>
            <SearchContainer>
              <SearchInput
                defaultValue={filter}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={onCancelSearch}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                onKeyDown={handleSearchKeyDown}
              />
              {filter && itemsCount && (
                <Title color="text.primary" variant="h6">
                  {`${itemsCount.toLocaleString('en-US')} items`}
                </Title>
              )}
            </SearchContainer>
          </Grid>
          )}
          {userProfile && (
          <Grid key="avatar" sm={2} xs={6} item>
            <AvatarBox>
              {!searchEnabled && itemsCount > 0 && (
                <ChipItemCount label={`${itemsCountFormatted} items`} />
              )}
              { timeNeeded > 0 && (
                <ChipTimeNeeded
                  label={timeNeeded > 60 ? `${timeNeededHours} hours` : `${timeNeededMinutes} minutes`}
                  title={`${timeNeededMinutes} minutes`}
                />
              )}
              { highResolution && !!percent && (
                <ChipTimeSpent
                  label={`${percent}% learned`}
                  title={`${timeSpentMinutes} minutes`}
                />
              )}
              { highResolution && tabValue === 0 && (
                <StyledButton
                  onClick={() => setItemEditDialog(true)}
                >
                  <EditIcon />
                </StyledButton>
              )}
              { highResolution && onListDelete && listInfo && !listInfo.autoGenerated && (
                <StyledButton
                  onClick={() => onListDelete(listInfo)}
                >
                  <DeleteIcon />
                </StyledButton>
              )}
              { highResolution && listInfo && (
                <StyledButton
                  onClick={() => onListRefresh(listInfo)}
                >
                  <RefreshIcon />
                </StyledButton>
              )}
              { highResolution && (
                <ButtonBase
                  onClick={
                    () => navigate(`/create/item/${listInfo ? listInfo.id : ''}`)
                    }
                >
                  <AddIcon />
                </ButtonBase>
              )}
              { highResolution && (
                <StyledButton
                  onClick={() => setSearchEnabled(!searchEnabled)}
                >
                  <SearchIcon />
                </StyledButton>
              )}
              <IconButton onClick={handleMenuOpen}>
                { imageUrl
                  ? <Avatar alt={givenName} src={imageUrl} />
                  : <AccountCircle /> }
              </IconButton>
            </AvatarBox>
            <StyledMenu
              anchorEl={menuAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              elevation={0}
              id="menu-appbar"
              open={menuOpen}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              onClose={handleMenuClose}
            >
              <StyledMenuItem>{givenName}</StyledMenuItem>
              <Divider />
              <StyledMenuItem
                key="settings"
                onClick={() => handleMenuClick('/settings')}
              >
                Settings
              </StyledMenuItem>
              { userInfo.isCurator && (
                <StyledMenuItem
                  key="curateItems"
                  onClick={() => handleMenuClick('/curate/items')}
                >
                  Curate Items
                </StyledMenuItem>
              )}
              { userInfo.isCurator && (
                <StyledMenuItem
                  key="curateLists"
                  onClick={() => handleMenuClick('/curate/lists')}
                >
                  Curate Lists
                </StyledMenuItem>
              )}
              { userInfo.isCurator && (
                <StyledMenuItem
                  key="curateAuthors"
                  onClick={() => handleMenuClick('/curate/authors')}
                >
                  Curate Authors
                </StyledMenuItem>
              )}
              { userInfo.isCurator && (
                <Divider />
              )}
              <StyledMenuItem key="logout" onClick={signOut}>
                Sign out
              </StyledMenuItem>
            </StyledMenu>
          </Grid>
          )}
          {!userProfile && (
          <Grid key="avatar" sm={2} xs={6} item>
            <AvatarBox>
              <LoginButton color="secondary" onClick={signIn}>
                Sign in
              </LoginButton>
            </AvatarBox>
          </Grid>
          )}
        </Grid>
      </StyledAppBar>
      <ViewDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <ItemEditDialog open={itemEditDialog} onClose={handleItemEditDialogClose} />
    </>
  );
}

export default Header;
