// TODO find a way to share with redis-client
export const MediaTypes = Object.freeze({
  Unknown: 0,
  Article: 1,
  Book: 2,
  Video: 3,
  Diagram: 4,
  Website: 5,
  Code: 6,
  Tool: 7,
  Digest: 8,
  Paper: 9,
  Discussion: 10,
  Course: 11,
  Music: 12,
  Podcast: 13,
  Presentation: 14,
  YouTubeChannel: 15,
  PodcastChannel: 16,
});

export const mediaTypeLables = [
  {
    value: MediaTypes.Unknown,
    label: 'Unknown',
  },
  {
    value: MediaTypes.Article,
    label: 'Article',
    color: 'green',
  },
  {
    value: MediaTypes.Book,
    label: 'Book',
    color: 'purple',
  },
  {
    value: MediaTypes.Video,
    label: 'Video',
    color: 'red',
  },
  {
    value: MediaTypes.Diagram,
    label: 'Diagram',
  },
  {
    value: MediaTypes.Website,
    label: 'Website',
  },
  {
    value: MediaTypes.Code,
    label: 'Code',
  },
  {
    value: MediaTypes.Tool,
    label: 'Tool',
  },
  {
    value: MediaTypes.Digest,
    label: 'Digest',
  },
  {
    value: MediaTypes.Paper,
    label: 'Paper',
  },
  {
    value: MediaTypes.Discussion,
    label: 'Discussion',
  },
  {
    value: MediaTypes.Course,
    label: 'Course',
  },
  {
    value: MediaTypes.Music,
    label: 'Music',
  },
  {
    value: MediaTypes.Podcast,
    label: 'Podcast Episode',
  },
  {
    value: MediaTypes.Presentation,
    label: 'Presentation',
  },
  {
    value: MediaTypes.YouTubeChannel,
    label: 'Youtube Channel',
  },
  {
    value: MediaTypes.PodcastChannel,
    label: 'Podcast',
  },
];

export function buildMediaTypeNameMap() {
  const result = {};
  mediaTypeLables.forEach((type) => {
    result[type.value] = type;
  });
  return result;
}
